import React from "react";
import {Dropdown, DropdownToggle} from "reactstrap";

function DropdownQuestionDisplay(props) {

    return (
        <div className="margin-top-bottom">
            <div className="question-text">{props.question}</div>
            <Dropdown className="margin-top-bottom" disabled={true}>
                <DropdownToggle disabled={true}>
                    {props.response === null ? "Select One..." : props.response}
                </DropdownToggle>
            </Dropdown>
        </div>
    )
}

export default DropdownQuestionDisplay;