import React, {useEffect, useState} from "react";
import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle} from "reactstrap";
import WrittenQuestion from "./WrittenQuestion";
import CheckBoxQuestion from "./CheckBoxQuestion";

function DropdownQuestion(props) {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedValue, setSelectedValue] = useState(null);
    
    // Init
    useEffect(() => {
        saveShallowCopy(null);
        
        if (props.required) {
            updateRequiredQuestion(false);
        }
    }, [])
    
    // On response refresh
    useEffect(() => {
        if (props.response[props.index] === undefined) {
            saveShallowCopy(null);
        } else {
            setSelectedValue(props.response[props.index].response);
        }
    }, [props.response, props.index])
    
    // Removes additional question response if user switched option
    useEffect(() => {
        if (selectedValue !== props.additionalQuestion.connection) {
            delete props.response[`${props.index}-1`]
        }
    }, [selectedValue])
    
    // Handle dropdown value change
    const handleChange = (value) => {
        setSelectedValue(value);
        saveShallowCopy(value);
        
        if (props.required && value !== null && value !== undefined) {
            updateRequiredQuestion(true);
        } else if (props.required && (value === null || value === undefined)) {
            updateRequiredQuestion(false);
        }
    }
    
    // Create a shallow copy of response
    const saveShallowCopy = (value) => {
        // Create shallow copy
        let responses = Object.assign({}, props.response)

        // Replace old response with new response
        responses[props.index] = {
            question: props.question,
            response: value,
            type: "dropdown"
        };

        // Update parent state
        props.setResponse(responses);
    }
    
    // Create a shallow copy of requiredQuestion
    const updateRequiredQuestion = (value) => {
        // Create shallow copy
        let requiredQuestion = Object.assign({}, props.requiredQuestion);

        // Replace old response with new response
        requiredQuestion[props.index] = {
            question: props.question,
            answered: value
        };

        // Update parent state
        props.setRequiredQuestion(requiredQuestion);
    }

    return (
        <div className={`question-container ${props.required ? "required" : ""}`}>
            <div className="question-text">{props.question}</div>
            <Dropdown isOpen={isOpen} toggle={() => setIsOpen(!isOpen)}>
                <DropdownToggle caret>
                    {selectedValue === null ? "Select One..." : selectedValue}
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu">
                    {props.choices.length > 0 && props.choices.map((choice, index) => (
                        <DropdownItem className="dropdown-item"
                                      key={`${props.index}-${index}`}
                                      onClick={() => handleChange(choice)}>{choice}
                        </DropdownItem>
                    ))}
                </DropdownMenu>
            </Dropdown>
            {props.additionalQuestion !== undefined && props.additionalQuestion.connection === selectedValue &&
            <div>
                {props.additionalQuestion.type === "written" &&
                <WrittenQuestion question={props.additionalQuestion.question}
                                 tooltip={props.additionalQuestion.tooltip}
                                 required={props.additionalQuestion.required}
                                 response={props.response}
                                 setResponse={props.setResponse}
                                 index={`${props.index}-1`}/>
                }
                {props.additionalQuestion.type === "dropdown" &&
                <DropdownQuestion question={props.additionalQuestion.question}
                                  tooltip={props.additionalQuestion.tooltip}
                                  choices={props.additionalQuestion.choices}
                                  additionalQuestion={props.additionalQuestion.additionalQuestion}
                                  required={props.additionalQuestion.required}
                                  response={props.response}
                                  setResponse={props.setResponse}
                                  index={`${props.index}-1`}/>
                }
                {props.additionalQuestion.type === "checkbox" &&
                <CheckBoxQuestion question={props.additionalQuestion.question}
                                  message={props.additionalQuestion.checkboxMessage}
                                  tooltip={props.additionalQuestion.tooltip}
                                  tooltipHeader={props.additionalQuestion.tooltipHeader}
                                  required={props.additionalQuestion.required}
                                  response={props.response}
                                  setResponse={props.setResponse}
                                  index={`${props.index}-1`}/>
                }
            </div>
            }
        </div>
    )
}

export default DropdownQuestion;