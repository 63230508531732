import React, {useState} from "react";
import ForumComment from "./ForumComment";
import Modal from "react-modal";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Base64ToArrayBuffer from "../Helpers/Base64ToArrayBuffer";
import {toast} from "react-toastify";

function CaseComments(props) {
    const [selectedAttachment, setSelectedAttachment] = useState({
        fileName: null,
        url: null,
        contentType: null,
        byteArray: null
    });
    const [modalIsOpen, setModalIsOpen] = useState(false);

    const openModal = async (fileName, attachment, contentType, byteArray) => {
        setSelectedAttachment({
            fileName: fileName,
            url: attachment,
            contentType: contentType,
            byteArray: byteArray
        });
        
        if (contentType !== "application/pdf")
            setModalIsOpen(true);
        else if (contentType === "application/pdf") 
            await createDownloadLink(fileName, attachment, byteArray, contentType);
    }

    const closeModal = () => {
        setSelectedAttachment({
            fileName: null,
            url: null,
            contentType: null,
            byteArray: null
        });
        setModalIsOpen(false);
    }

    const createDownloadLink = async (filename, url, byteArray, contentType) => {
        var bytes = Base64ToArrayBuffer(byteArray);
        var blob = new Blob([bytes], {type: contentType});

        const link = document.createElement('a');
        const fileName = filename.substring(filename.indexOf("/") + 1);

        link.href = window.URL.createObjectURL(blob);
        link.setAttribute("download", `${fileName}`);

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        document.body.removeChild(link);
    }

    return (
        <div className="case-forum-comments-container card" id="case-forum-comments-container">
            {props.comments.map((x, i) => (
                <ForumComment comment={x} onAttachmentClick={openModal}/>
            ))}

            {selectedAttachment && selectedAttachment.fileName !== null && selectedAttachment.url !== null && selectedAttachment.contentType !== null &&
            <Modal className="case-forum-modal-container"
                   isOpen={modalIsOpen}>
                <div className="modal-header">
                    <h4>
                        {selectedAttachment.fileName.split("/")[1]}
                    </h4>
                    <FontAwesomeIcon icon={"times"} onClick={closeModal} style={{cursor: "pointer"}}/>
                </div>
                <div className="modal-body">
                    {selectedAttachment.contentType.split("/")[0] === "image" &&
                    <embed className="modal-attachment" src={selectedAttachment.url}/>
                    }
                    {selectedAttachment.contentType.split("/")[0] === "video" &&
                    <video controls>
                        <source src={selectedAttachment.url} type="video/mp4"/>
                    </video>
                    }
                </div>
            </Modal>
            }
        </div>
    )
}

export default CaseComments;