import React from "react";
import cn from "classnames";
import moment from "moment";

function CaseStatusCard(props) {

    return (
        <div className={cn("case-card-container card", {
            "open-status": props.status.isOpen,
            "closed-status": !props.status.isOpen
        })}>
            <div className="card-body d-flex flex-column" style={{ overflowY: "auto" }}>
                <h2 className="card-title">{props.status.isOpen ? "Open" : "Closed"}</h2>
                <p className="card-text" style={{ fontSize: "1.5em"}}>{props.status.reason}</p>
                <section style={{
                    marginTop: "auto",
                    marginLeft: "auto",
                    fontSize: "1.25em",
                    fontWeight: "625"
                }}>
                    {props.status.isOpen ? "Opened on: " : "Closed on: "}
                    {moment(props.status.dateCreated).format('MMMM Do YYYY, h:mm:ss a')}
                </section>
            </div>
        </div>
    )
}

export default CaseStatusCard;