import React, {useContext, useEffect, useState} from 'react';
import CasesSidebar from "../../CasesSidebar";
import CaseReview from "../../CaseReview";
import '../CasePage/case.css';
import {AdminContext} from "../../Contexts/AdminContext";
import _ from "lodash";

function Admin(props) {
    const {
        properties,
        getComments,
        createComment,
        getAttachments,
        getForumAttachments,
        createStatus,
        setCurrentCase,
        getActiveCases,
        getInactiveCases,
        getDateFilter
    } = useContext(AdminContext);
    const [cases, setCases] = useState(null);
    const [filteredCases, setFilterCases] = useState([]);

    // Init
    useEffect(() => {
        getActiveCases().then(r => {
            setCases(r);
        })
    }, [])

    // Api calls to update cases depending on active filter
    useEffect(() => {
        switch(properties.activeFilter) {
            case true:
                getActiveCases().then(r => {
                    setCases(r);
                })
                break;
            case false:
                getInactiveCases().then(r => {
                    setCases(r);
                })
                break;
        }
        
    }, [properties.activeFilter])

    // Update filtered cases everytime date filter changes
    useEffect(() => {
        if (cases !== undefined && cases !== null) {
            const tempCases = _.filter(cases, function (o) {
                let dateCreated = new Date(o.dateCreated);
                
                let startDate = properties.dateFilter.startDate;
                let endDate = properties.dateFilter.endDate;
                
                if (dateCreated >= startDate && dateCreated <= endDate) {
                    return o;
                }
            })

            setFilterCases(tempCases);
        }
    }, [properties.dateFilter, cases])
    
    // Removes case from sidebar by caseId
    const removeCaseFromSidebar = (caseId) => {
        // Remove from original case array
        const index = cases.findIndex(x => x.caseId === caseId);
        cases.splice(index, 1);
        
        // Remove from filtered array
        const indexFiltered = filteredCases.findIndex(x => x.caseId === caseId);
        filteredCases.splice(indexFiltered, 1);
    }
    
    return (
        <>
            <div className="d-flex flex-lg-row">
                <CasesSidebar cases={filteredCases} dateRange={getDateFilter()}/>
                <CaseReview currentCase={properties.currentCase}
                            getComments={getComments}
                            createComment={createComment}
                            getAttachments={getAttachments}
                            getForumAttachments={getForumAttachments}
                            setCurrentCase={setCurrentCase}
                            createStatus={createStatus}
                            removeCaseFromSidebar={removeCaseFromSidebar}
                            isAdmin={true}/>
            </div>
        </>
    )
}

export default Admin;