import React, {Component} from "react";
import {CaseContext} from "../Contexts/CaseContext";
import {serialize} from "object-to-formdata";

export class CaseProvider extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activePage: "Agreement",
            steps: {
                'Agreement': true,
                'Report': false,
                'Category': false,
                'Additional Information': false,
                'Submit': false
            },
            caseId: null,
            report: "",
            attachments: {},
            selectedCategory: null,
            selectedSubCategory: null,
            category: null,
            subCategory: null,
            isLegalCounsel: false,
            responses: {}
        };
        // Upon Refresh
        if (window.performance) {
            this.state.caseId = sessionStorage.getItem('caseId')
        }
    }
    
    // Set case id
    setCaseId = (value) => {
        this.setState({caseId: value});
    }

    // Set active page
    setActivePage = (value) => {
        this.setState({activePage: value});
    }

    // Update timeline
    updateTimeline = (key, value) => {
        this.state.steps[key] = value;
    }

    // Update selected category
    setSelectedCategory = (index, value) => {
        this.setState({
            selectedCategory: index,
            category: value
        });
    }

    // Update selected subcategory
    setSelectedSubCategory = (index, value) => {
        this.setState({
            selectedSubCategory: index,
            subCategory: value
        });
    }

    // Update report
    setReport = (value) => {
        this.setState({report: value});
    }

    // Set attachments
    setAttachment = (value) => {
        this.setState({attachments: value});
    }
    
    // Add attachment
    addAttachment = (key, value) => {
        this.state.attachments[key] = value;
    }

    // Delete attachment
    deleteAttachment = (key) => {
        delete this.state.attachments[key];
    }

    // Update 
    updateLegalCouncil = () => {
        this.setState({isLegalCounsel: !this.state.isLegalCounsel});
    }

    // Update response
    updateResponse = (value) => {
        this.setState({responses: value});
    }

    // Generate case
    generateCase = () => {
        if(!this.state.caseId)
            this.state.caseId = sessionStorage.getItem('caseId')

            return JSON.stringify({
            caseId: this.state.caseId,
            report: this.state.report,
            category: this.state.category,
            subCategory: this.state.subCategory,
            responses: this.state.responses,
            isLegalCounsel: this.state.isLegalCounsel, 
            email: this.state.email
        });
    }

    // Generate attachment
    generateAttachments = () => {
        if(!this.state.caseId)
            this.state.caseId = sessionStorage.getItem('caseId')
        const jsonObject = {
            caseId: this.state.caseId,
            attachments: this.state.attachments
        };

        return serialize(jsonObject);
    }

    render() {
        return (
            <CaseContext.Provider
                value={{
                    currentCase: this.state,
                    setCaseId: this.setCaseId,
                    setActivePage: this.setActivePage,
                    updateTimeline: this.updateTimeline,
                    setSelectedCategory: this.setSelectedCategory,
                    setSelectedSubCategory: this.setSelectedSubCategory,
                    setReport: this.setReport,
                    setAttachment: this.setAttachment,
                    addAttachment: this.addAttachment,
                    deleteAttachment: this.deleteAttachment,
                    updateLegalCounsel: this.updateLegalCouncil,
                    updateResponse: this.updateResponse,
                    generateCase: this.generateCase,
                    generateAttachments: this.generateAttachments
                }}
            >
                {this.props.children}
            </CaseContext.Provider>
        )
    }
}