import React from 'react';

function CaseOptions(props) {
    return (
        <div className="d-flex flex-md-row justify-content-between case-options">
            <div>
                <input type="radio"
                       className="btn-check"
                       name="options" id="Report"
                       autoComplete="off"
                       onClick={() => props.setActiveCasePage("Report")}
                       checked={props.activeCasePage === "Report"}/>
                <label className="btn btn-secondary w-100" htmlFor="Report">Report</label>
            </div>
            <div>
                <input type="radio"
                       className="btn-check"
                       name="options" id="Responses"
                       autoComplete="off"
                       onClick={() => props.setActiveCasePage("Responses")}
                       checked={props.activeCasePage === "Responses"}/>
                <label className="btn btn-secondary w-100" htmlFor="Responses">Responses</label>
            </div>
            <div>
                <input type="radio"
                       className="btn-check"
                       name="options" id="Attachments"
                       autoComplete="off"
                       onClick={() => props.setActiveCasePage("Attachments")}
                       checked={props.activeCasePage === "Attachments"}/>
                <label className="btn btn-secondary w-100" htmlFor="Attachments">Attachments</label>
            </div>
            <div>
                <input type="radio"
                       className="btn-check"
                       name="options" id="Forum"
                       autoComplete="off"
                       onClick={() => props.setActiveCasePage("Forum")}
                       checked={props.activeCasePage === "Forum"}/>
                <label className="btn btn-secondary w-100" htmlFor="Forum">Forum</label>
            </div>
            <div>
                <input type="radio"
                       className="btn-check"
                       name="options" id="Status"
                       autoComplete="off"
                       onClick={() => props.setActiveCasePage("Status")}
                       checked={props.activeCasePage === "Status"}/>
                <label className="btn btn-secondary w-100" htmlFor="Status">Status</label>
            </div>
        </div>
    )
}

export default CaseOptions;