import React, {Component} from "react";
import {UserContext} from "../Contexts/UserContext";
import {AuthContext} from "../Contexts/AuthContext";
import axios from "axios";
import {serialize} from "object-to-formdata";
import {toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import User from "../Pages/User/User";

toast.configure();

export class UserProvider extends Component {
    static contextType = AuthContext;

    constructor(props) {
        super(props);
        this.state = {
            currentCase: {
                case: null,
                comments: [],
                attachments: [],
                forumAttachments: []
            }
        };
    }

    // init
    componentDidMount() {
        this.getCase();
    }

    // Call API to get user case
    getCase = async () => {
        let userCase = JSON.parse(sessionStorage.getItem('case'))
        if(userCase === null || userCase === undefined) {
            const {auth} = this.context;
            await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/api/Case/get-case/${auth.caseId}`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${sessionStorage.getItem('bearerToken')}`
                    }
                })
                .then(response => {
                    if (response.status === 200) {
                        this.setState({
                            currentCase: {
                                ...this.state.currentCase,
                                case: response.data.case
                            }
                        });
                        sessionStorage.setItem('case', JSON.stringify(response.data.case))
                    } else {
                        toast.error("An unexpected error has occured. Please try again later. If the problem persists contact support for further assistance.", {
                            position: toast.POSITION.BOTTOM_CENTER
                        })
                    }
                }).catch(() => {
                    toast.error("An unexpected error has occured. Please try again later. If the problem persists contact support for further assistance.", {
                        position: toast.POSITION.BOTTOM_CENTER
                    })
                })
        } else{
            this.setState({
                currentCase: {
                    ...this.state.currentCase,
                    case: userCase
                }
            });
        }
    }

    // API call to get case attachments
    getAttachments = async () => {
        await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/api/Attachment/get-attachments-for-case/${this.state.currentCase.case.caseId}`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${sessionStorage.getItem('bearerToken')}`
                }
            })
            .then(response => {
                if (response.status === 200) {
                    this.setState({
                        currentCase: {
                            ...this.state.currentCase,
                            attachments: response.data.attachments
                        }
                    });
                } else {
                    toast.error("An unexpected error has occured. Please try again later. If the problem persists contact support for further assistance.", {
                        position: toast.POSITION.BOTTOM_CENTER
                    })
                }
            }).catch(() => {
                toast.error("An unexpected error has occured. Please try again later. If the problem persists contact support for further assistance.", {
                    position: toast.POSITION.BOTTOM_CENTER
                })
            })
    }

    // API call to get case attachments
    getForumAttachments = async () => {
        await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/api/Attachment/get-forum-attachments-for-case/${this.state.currentCase.case.caseId}`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${sessionStorage.getItem('bearerToken')}`
                }
            })
            .then(response => {
                if (response.status === 200) {
                    this.setState({
                        currentCase: {
                            ...this.state.currentCase,
                            forumAttachments: response.data.attachments
                        }
                    });
                } else {
                    toast.error("An unexpected error has occured. Please try again later. If the problem persists contact support for further assistance.", {
                        position: toast.POSITION.BOTTOM_CENTER
                    })
                }
            }).catch(() => {
                // ignore for now
                toast.error("An unexpected error has occured. Please try again later. If the problem persists contact support for further assistance.", {
                    position: toast.POSITION.BOTTOM_CENTER
                })
            })
    }

    // API call to get forum comments
    getComments = async () => {
        await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/api/ForumComments/get-forum-comments-for-a-case/${this.state.currentCase.case.caseId}`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${sessionStorage.getItem('bearerToken')}`
                }
            })
            .then(response => {
                if (response.status === 200) {
                    if(response.data.result === 'Forum Comments Returned Successfully')
                        this.setState({
                        currentCase: {
                            ...this.state.currentCase,
                            comments: response.data.forumComments
                        }
                    });
                }
                else {
                    toast.error("An unexpected error has occured. Please try again later. If the problem persists contact support for further assistance.", {
                        position: toast.POSITION.BOTTOM_CENTER
                    })
                }
            }).catch(() => {
                toast.error("An unexpected error has occured. Please try again later. If the problem persists contact support for further assistance.", {
                    position: toast.POSITION.BOTTOM_CENTER
                })
            })
    }

    // API call to create forum comment
    createComment = async (comment) => {
        await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/api/ForumComments/create-forum-comment/`,
            serialize(comment),
            {
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: `Bearer ${sessionStorage.getItem('bearerToken')}`
                }
            })
            .then(response => {
                if (response.status === 200) {
                    // this.setState({
                    //     currentCase: {
                    //         ...this.state.currentCase,
                    //         comments: [...this.state.comments, comment]
                    //     }
                    // })
                } else {
                    toast.error("An unexpected error has occured. Please try again later. If the problem persists contact support for further assistance.", {
                        position: toast.POSITION.BOTTOM_CENTER
                    })
                }
            }).catch(() => {
                toast.error("An unexpected error has occured. Please try again later. If the problem persists contact support for further assistance.", {
                    position: toast.POSITION.BOTTOM_CENTER
                })
            })
    }

    render() {
        return (
            <UserContext.Provider
                value={{
                    properties: this.state,
                    createComment: this.createComment,
                    getComments: this.getComments,
                    getAttachments: this.getAttachments,
                    getForumAttachments: this.getForumAttachments,
                    getCase: this.getCase
                }}
            >
                {this.props.children}
            </UserContext.Provider>
        )
    }
}