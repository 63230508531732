import React, {useState, useContext, useEffect} from "react";
import {Tooltip} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {CaseContext} from "./Contexts/CaseContext";

function CategoryElement(props) {
    const [toolTipOpen, setToolTipOpen] = useState(false);
    const [active, setActive] = useState(false);
    const {currentCase, setSelectedCategory, setSelectedSubCategory} = useContext(CaseContext);

    useEffect(() => {
        updateStatus();
    }, [currentCase.selectedSubCategory])

    const toggle = () => setToolTipOpen(!toolTipOpen);
    const updateStatus = () => currentCase.selectedCategory === props.category &&
                               currentCase.selectedSubCategory === props.index ? setActive(true) 
                                                                               : setActive(false);

    const onCategoryClick = () => {
        // Check if current selected category is another category or same category
        if (currentCase.selectedSubCategory !== props.index) {
            setSelectedCategory(props.category, props.categoryTitle);
            setSelectedSubCategory(props.index, props.message);
            setActive(true);

            return;
        }

        setSelectedCategory(null, null);
        setSelectedSubCategory(null, null);
        setActive(false);
    }

    return (
        <div className="flex-row align-items-center">
            <input type="checkbox" onChange={() => onCategoryClick()} checked={active}/>
            <label>
                <div className="flex-row ">
                    <div className="category-message">{props.message}</div>
                    <div id={`${props.category}-${props.index}`}>
                        <FontAwesomeIcon icon="info-circle"/>
                    </div>
                    <Tooltip className="category-tooltip"
                             placement="right"
                             isOpen={toolTipOpen}
                             toggle={toggle}
                             target={`${props.category}-${props.index}`}>
                        <div className="d-flex flex-md-column align-items-start">
                            <h5>Category Details</h5>
                            <div>{props.tooltipHeader}:</div>
                            <ul className="d-flex flex-sm-column align-items-start">
                                {props.tooltipBody.map((value, index) =>
                                    <li key={index}>{value}</li>
                                )}
                            </ul>
                        </div>
                    </Tooltip>
                </div>
            </label>
        </div>
    )
}

export default CategoryElement;