import React, {useState} from 'react';
import CaseOptions from "./CaseOptions";
import CaseReport from "./CaseReport";
import CaseResponses from "./CaseResponses";
import CaseAttachments from "./CaseAttachments";
import CaseForum from "./CaseForum";
import cn from "classnames";
import CaseStatus from "./CaseStatus";
import NoContent from "./Pages/NoContent/NoContent";
import axios from "axios";
import {toast} from "react-toastify";

const CaseReview = props => {
    const [activeCasePage, setActiveCasePage] = useState("Report");

    const deleteCase = async (caseId) => {
        await axios.delete(`${process.env.REACT_APP_API_ENDPOINT}/api/Case/all-cases/${caseId}`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${sessionStorage.getItem('bearerToken')}`
                }
            })
            .then(response => {
                if (response.status === 200) {
                    toast.success("Case Deleted Successfully", {
                        position: toast.POSITION.BOTTOM_CENTER
                    })
                } else {
                    toast.error("An unexpected error has occured. Please try again later. If the problem persists contact support for further assistance.", {
                        position: toast.POSITION.BOTTOM_CENTER
                    })
                }
            }).catch(() => {
                toast.error("An unexpected error has occured. Please try again later. If the problem persists contact support for further assistance.", {
                    position: toast.POSITION.BOTTOM_CENTER
                })
            })
    }
    
    return (
        <>
            {props.currentCase.case === null && props.isAdmin &&
            <div className="d-flex flex-md-column w-75">
                <div id="case-review-container">
                    <NoContent/>
                </div>
            </div>
            }

            {props.currentCase.case !== null &&
            <div className={cn("d-flex flex-md-column", {
                "w-75": props.isAdmin,
                "w-100": !props.isAdmin
            })}>
                <div id="case-review-container">
                    <CaseOptions activeCasePage={activeCasePage} setActiveCasePage={setActiveCasePage}/>

                    <div id="case-review-header">
                        <h2>
                            <strong>Case #</strong>
                            <span className="text-right">{props.currentCase.case.caseNumber.toUpperCase()}</span>
                        </h2>
                        <h4>
                            <strong>Category:</strong>
                            <span className="text-right">{props.currentCase.case.category}</span>
                        </h4>
                        <h5>
                            <strong>Subcategory:</strong>
                            <span className="text-right">{props.currentCase.case.subCategory}</span>
                        </h5>
                    </div>

                    <div id="case-review-body">
                        {activeCasePage === "Report" &&
                        <CaseReport report={props.currentCase.case.report}/>
                        }
                        {activeCasePage === "Responses" &&
                        <CaseResponses case={props.currentCase.case}/>
                        }
                        {activeCasePage === "Attachments" &&
                        <CaseAttachments currentCase={props.currentCase}
                                         getAttachments={props.getAttachments}
                                         getForumAttachments={props.getForumAttachments}/>
                        }
                        {activeCasePage === "Forum" &&
                        <CaseForum currentCase={props.currentCase}
                                   getComments={props.getComments}
                                   createComment={props.createComment}/>
                        }
                        {activeCasePage === "Status" &&
                        <CaseStatus currentCase={props.currentCase}
                                    setCurrentCase={props.setCurrentCase}
                                    createStatus={props.createStatus}
                                    deleteCase={deleteCase}
                                    removeCaseFromSidebar={props.removeCaseFromSidebar}/>
                        }
                    </div>
                </div>
            </div>
            }
        </>
    )
};

export default CaseReview;