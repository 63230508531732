import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import "./nocases.css";

function NoCases(props) {
    return (
        <div className="no-cases-container">
            <div className="text-container">
                <h5>Looks like there are no cases.</h5>
                <p style={{fontWeight: "lighter", fontSize: "0.75em;"}}>Check again at a later time.</p>
            </div>
            <div className="icon-container">
                <FontAwesomeIcon icon={"puzzle-piece"} size={"5x"}/>
            </div>
        </div>
    )
}

export default NoCases;