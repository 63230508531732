import React, {useEffect, useContext} from "react";
import {Route} from "react-router-dom";
import {AuthContext} from "./Contexts/AuthContext";
import Unauthorized from "./Pages/Unauthorized/Unauthorized";

const PrivateRoute = ({component: Component, provider: Provider, path, ...rest}) => {
    const {auth} = useContext(AuthContext);

    useEffect(() => {
        if (auth.bearerToken === null) {
            return;
        }
    }, [auth.bearerToken, path]);
    
    const render = (props) => {
        if (auth.bearerToken !== null && Provider !== undefined) {
            return (
                <Provider>
                    <Component {...props}/>
                </Provider>
            )
        } else if (auth.bearerToken !== null && Provider === undefined) {
            return (
                <Component {...props}/>   
            )
        } else {
            return <Unauthorized />
        }
    }

    return <Route path={path} render={render} {...rest} />;
};

export default PrivateRoute;