import React, {useContext, useEffect, useState} from "react";
import CheckBoxQuestionDisplay from "./CheckBoxQuestionDisplay";
import WrittenQuestionDisplay from "./WrittenQuestionDisplay";
import DropdownQuestionDisplay from "./DropdownQuestionDisplay";

function CaseResponses(props) {
    const [responses, setResponses] = useState(null);

    useEffect(() => {
        // Parse string object to JSON object
        if (props.case !== null) {
            let jsonObject = JSON.parse(props.case.responses);

            // Sort Json object
            let orderedJsonObject = Object.keys(jsonObject).sort().reduce((obj, key) => {
                    obj[key] = jsonObject[key]
                    return obj;
                }, {});
            
            setResponses(orderedJsonObject);
        }
    }, [props.case])
 
    return (
        <div className="case-subpage-container">
            {responses &&
            <div className="h-100" style={{overflowY: "scroll"}}>
                {Object.values(responses).map((x, i) => (
                    <div>
                        {x.type === "checkbox" &&
                        <CheckBoxQuestionDisplay question={x.question} response={x.response} message={x.message}/>
                        }
                        {x.type === "written" &&
                        <WrittenQuestionDisplay question={x.question} response={x.response} index={i}/>
                        }
                        {x.type === "dropdown" &&
                        <DropdownQuestionDisplay question={x.question} response={x.response}/>
                        }
                    </div>
                ))}
            </div>
            }
        </div>
    )
}

export default CaseResponses;