import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import "./noattachments.css";

function NoAttachments(props) {

    return (
        <div className="no-attachments-container">
            <div className="text-container">
                <h4>Looks like there are no attachments.</h4>
                <p style={{fontWeight: "lighter"}}>Refer to the forum section to add forum attachments.</p>
            </div>
            <div className="icon-container">
                <FontAwesomeIcon icon={"folder-open"} size={"10x"}/>
            </div>
        </div>
    )
}

export default NoAttachments;