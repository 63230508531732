import React, {useContext} from "react";
import CaseReview from "../../CaseReview";
import {UserContext} from "../../Contexts/UserContext";
import "../CasePage/case.css";

function User() {
    const {properties, getComments, createComment, getAttachments, getForumAttachments, getCase} = useContext(UserContext);

    return (
        <div className="d-flex flex-lg-row">
            <CaseReview currentCase={properties.currentCase}
                        getComments={getComments}
                        createComment={createComment}
                        getAttachments={getAttachments}
                        getForumAttachments={getForumAttachments}
                        updateCurrentCase={getCase}
                        isAdmin={false}/>
        </div>
    )
}

export default User;