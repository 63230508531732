import React, {useState, useEffect, useContext, useCallback} from "react";
import CommentBox from "./CommentBox";
import CaseComments from "./CaseComments";
import moment from "moment";
import {AuthContext} from "./Contexts/AuthContext";
import useCountDown from "react-countdown-hook";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {UncontrolledTooltip} from "reactstrap";

const INITIAL_TIME = 60 * 1000;
const INTERVAL = 1000;

function CaseForum(props) {
    const {auth} = useContext(AuthContext);

    const [timeLeft, {start, pause, resume, reset}] = useCountDown(INITIAL_TIME, INTERVAL);

    // Init
    useEffect(() => {
        start();
        scrollToBottom();
    }, [])

    useEffect(() => {
        if (props.currentCase.case !== null) {
            props.getComments();
        }
    }, [props.currentCase.case])

    // Once timer reaches 0, retrieve comments
    useEffect(() => {
        if (timeLeft === 0) {
            props.getComments();
            start(INITIAL_TIME);
        }
    }, [timeLeft])

    // Refresh now 
    const refresh = useCallback(() => {
        props.getComments()
        start(INITIAL_TIME);
    }, [])

    // Scroll to bottom of forum comments container
    const scrollToBottom = () => {
        let forumCommentsContainer = document.getElementById("case-forum-comments-container");
        forumCommentsContainer.scrollTop = forumCommentsContainer.scrollHeight;
    }

    // Todo: Add user id here once we get it
    // Append new comment to array
    const submit = async (comment, attachments) => {
        if (comment !== "" || Object.keys(attachments).length > 0) {
            const commentObject = {
                caseId: props.currentCase.case.caseId,
                message: comment === null ? "" : comment,
                dateCreated: moment(Date.now()).format(),
                userId: auth.caseId,
                isLegalCounsel: props.currentCase.case.isLegalCounsel,
                attachments: attachments,
                userForumComments: [{
                    "user": {
                        userName: auth.userName
                    }
                }],
            }

            // Create comment and refresh forum comments container
            await props.createComment(commentObject);
            await props.getComments();

            // Scroll to bottom
            scrollToBottom()
        }
    }

    return (
        <div className="case-forum-container">
            <div className="case-forum-refresh-counter"
                 id={"refresh-counter"}
                 onClick={refresh}>
                <FontAwesomeIcon icon={"sync-alt"}/>
                <span style={{color: "lightsteelblue"}}> Next refresh: {timeLeft / 1000}</span>
            </div>
            <UncontrolledTooltip
                placement="bottom"
                target={"refresh-counter"}>
                <div style={{textAlign: "left"}}>
                    <h4>Disclaimer</h4>
                    <p>The forum is not a live chat application, pressing the button will retrieve new messages if they
                        exist.</p>
                </div>
            </UncontrolledTooltip>
            <CaseComments comments={props.currentCase.comments}/>
            {props.currentCase.case.statuses.length === 1 &&
            (<CommentBox submit={submit}/>)
            }
        </div>
    )
}

export default CaseForum;