import React, {useContext, useEffect, useState} from "react";
import './MainPage.css';
import {Link} from "react-router-dom";
import {AuthContext} from "../../Contexts/AuthContext";
import {UserContext} from "../../Contexts/UserContext";

const MainPage = () => {
    const {auth} = useContext(AuthContext);
    return (
        <>
            <h1 style={{textAlign: "center"}}>Welcome to Energera Safe Space</h1>
            {auth.bearerToken === null && (
                <body>
                <div className="main-blob">
                    <section>
                        Integrity and honesty have always been central to the way we operate as an organization every
                        day. Everyone connected to the organization including, employees, consultants, suppliers, and
                        customers have a role to play in maintaining these values and protecting our organization’s
                        reputation. Together, we make Energera Inc. (“Energera”) a great place to work and do business.
                        Our values must be woven into our daily actions and supported with the right tools and
                        processes.
                    </section>
                    <section>
                        To help us further these values, Energera maintains a reporting system that allows employees to
                        anonymously and confidentially make inquiries, suggestions, and report cases of workplace
                        misconduct and other (suspected violations of Energera’s Policies, including its Code of
                        Business Conduct and Ethics, or suspected violation of law or regulation that govern Energera’s
                        operations). You can safely and anonymously submit reports through this Energera Safe Space
                        website which is easy to use and available 24/7. Your identity will not be revealed unless you
                        include identifying information with your submission.
                    </section>
                    <section>
                        Everyone needs to have the ability to speak up in a safe and secure way when they see something
                        that does not seem right. We encourage you to speak to your manager about any issues or
                        questions you may have on the job, but we also recognize that situations may exist where you
                        would prefer to ask a question, make a comment, or make a report anonymously. Your report will
                        be fully reviewed and investigated. All reports will be handled in a fair and timely manner.
                    </section>
                    <section>
                        Thank you for your attention to this new initiative. We strongly feel that we all have a
                        responsibility to help protect Energera, its employees, and the many people who do business with
                        us from the possible harm that can arise from unethical behavior or questionable business
                        practices.
                    </section>
                    <section>
                        As part of the submission process, you will be provided with a Case ID and password that will
                        allow you to log in and check the status of your submission. Using your Case ID and password,
                        you will be able to review information about your submission, ask the organization’s reviewers
                        questions, and receive updates from them until your inquiry, comment or report has been fully
                        addressed.
                    </section>
                </div>
                <main className="form-signin">
                    <h1 style={{textAlign: "center"}} className="h3 mb-3 fw-normal">What would you like to do? </h1>

                    <Link to='/register-new-page' className="w-100 btn btn-lg btn-outline-primary"
                          style={{marginBottom: "20px"}}>Create
                        New Case</Link>
                    <br/>
                    <Link type="button" to='/login' className="w-100 btn btn-lg btn-outline-primary">View Existing
                        Case</Link>
                </main>
                </body>
            )}
        </>
    )
};

export default MainPage;