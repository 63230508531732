import React, {useEffect, useState} from "react";
import {Link, useLocation} from "react-router-dom";
import axios from "axios";
import {toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


export const CreateUser = () => {
    const [email, setEmail] = useState(null);
    const [userName, setUserName] = useState(null);
    const [resetPasswordToken, setResetPasswordToken] = useState(null);
    const [newPassword, setNewPassword] = useState(null)
    const [role, setRole] = useState(null)
    const [redirectToLogin, setRedirectToLogin] = useState(false)
    const [loading, setLoading] = useState(false)
    const search = useLocation().search;
    toast.configure();

    useEffect(() => {
        setEmail(new URLSearchParams(search).get('email'))
        setResetPasswordToken(new URLSearchParams(search).get('token'))
        setRole(new URLSearchParams(search).get('role'))
    }, [])

    const submit = async (e) => {
        setLoading(true)
        e.preventDefault();
            await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/api/Users/create-user-with-role`,
                JSON.stringify({
                    "token": resetPasswordToken,
                    "email": email,
                    "password": newPassword,
                    "userName": userName,
                    "role": role
                }),
                {
                    headers: {
                        "Content-Type": "application/json"
                    }
                })
                .then(response => {
                    if (response.status === 200) {
                        if (response.data.status === 'Success') {
                            toast.success(response.data.message, {
                                position: toast.POSITION.BOTTOM_CENTER
                            });
                            setRedirectToLogin(true)
                            setLoading(false)
                        } else if (response.data.status === 'Failed')
                            toast.error(response.data.errors[0], {
                                position: toast.POSITION.BOTTOM_CENTER
                            });
                        setLoading(false)

                    }
                })
                .catch(e => {
                    toast.error("An unexpected error has occured. Please try again later. If the problem persists contact support for further assistance.", {
                        position: toast.POSITION.BOTTOM_CENTER
                    })
                    setLoading(false)
                })
    }

    return (<>
        {email && resetPasswordToken && (<main className="form-signin">
            <form onSubmit={submit}>
                <h1 className="h3 mb-3 fw-normal">Create {role.replace(/([A-Z])/g, ' $1').trim()} Account</h1>
                <input id="inputEmail" className="form-control" placeholder="User Name"
                       onChange={e => setUserName(e.target.value)}
                       required autoFocus/>
                <input type="password" id="inputPassword" className="form-control" placeholder="Password"
                       onChange={e => setNewPassword(e.target.value)}
                       required/>
                <div><strong>Password must contain</strong></div>
                <ul>
                    <li>At least 12 characters—the more characters, the better</li>
                    <li>A mixture of both uppercase and lowercase letters</li>
                    <li>Inclusion of at least one special character, e.g., ! @ # ? ]</li>
                    <li>A mixture of letters and numbers</li>
                </ul>
                <button className="w-100 btn btn-lg btn-primary" type="submit" disabled={loading}>                  
                    {loading ? "Loading..." : "Create Account"}
                </button>
                {redirectToLogin && (<>
                    <br/>
                    <br/>
                    <Link className="w-100 btn btn-lg btn-success" to='/login'>Proceed to Login Page</Link>
                </>)}
            </form>
        </main>)}
    </>)
}