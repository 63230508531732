import {
    isItAdmin,
    isExecutiveAssistant,
    isLegalCounsel,
    isChairPerson
} from "./AccessControlService";

const DetermineUserType = async (apiEndpoint) => {
    if (await isLegalCounsel(apiEndpoint)) {
        return "LegalCounsel";
    } else if (await isExecutiveAssistant(apiEndpoint)) {
        return "ExecutiveAssistant";
    } else if (await isItAdmin(apiEndpoint)) {
        return "ItAdmin";
    } else if (await isChairPerson(apiEndpoint)) {
        return "ChairPerson"
    }else {
        return "User"
    }
};

export default DetermineUserType;