import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import "./nocontent.css";

function NoContent(props) {
    
    return (
        <div className="no-content-container">
            <div className="text-container">
                <h4>Looks like a case hasn't been selected yet.</h4>
                <p style={{fontWeight: "lighter"}}>Please select a case from the sidebar on the left.</p>
            </div>
            <div className="icon-container">
                <FontAwesomeIcon icon={"suitcase"} size={"10x"}/>
            </div>
        </div>
    )
}

export default NoContent;