import React, {useEffect, useState} from 'react';
import TextArea from "./TextArea";

function WrittenQuestion(props) {
    const [answer, setAnswer] = useState("");
    
    // Init
    useEffect(() => {
        saveShallowCopy("");

        if (props.required && props.response[props.index] === undefined) {
            updateRequiredQuestion(false);
        }
    }, [])

    // On response refresh
    useEffect(() => {
        if (props.response[props.index] === undefined) {
            saveShallowCopy("");
        } else {
            setAnswer(props.response[props.index].response);
        }
    }, [props.response, props.index])
    
    // Handles user typing in text area
    const handleChange = (event) => {
        setAnswer(event.target.value);
        saveShallowCopy(event.target.value);
        
        if (props.required && event.target.value !== null && event.target.value !== undefined && event.target.value !== "") {
            updateRequiredQuestion(true);
        } else if (props.required && (event.target.value === null || event.target.value === undefined || event.target.value === "")) {
            updateRequiredQuestion(false);
        }
    }

    // Create a shallow copy of response
    const saveShallowCopy = (value) => {
        // Create shallow copy
        let responses = Object.assign({}, props.response)

        // Replace old response with new response
        responses[props.index] = {
            question: props.question,
            response: value,
            type: "written"
        };

        // Update parent state
        props.setResponse(responses);
    }

    // Create a shallow copy of requiredQuestion
    const updateRequiredQuestion = (value) => {
        // Create shallow copy
        let requiredQuestion = Object.assign({}, props.requiredQuestion);

        // Replace old response with new response
        requiredQuestion[props.index] = {
            question: props.question,
            answered: value
        };

        // Update parent state
        props.setRequiredQuestion(requiredQuestion);
    }

    return (
        <div className={`question-container ${props.required ? "required" : ""}`}>
            <div className="question-text">{props.question}</div>
            <div className="d-flex flex-column" style={{ height: "200px"}}>
                <TextArea maxLength={500} value={answer} onChange={handleChange}/>
            </div>
        </div>
    )
}

export default WrittenQuestion;