import React, {useState, useContext} from "react";
import {CaseContext} from "../../Contexts/CaseContext";
import FracShackCoreValues from "../../../JsonObjects/FracShackCoreValues.json";
import "./agreement.css";
import CoreValueCard from "../../CoreValueCard";
import Modal from "react-modal";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import PdfDocument from "../../PdfDocument";

function Agreement(props) {
    const [acceptance, setAcceptance] = useState(false);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [selectedPdf, setSelectedPdf] = useState({
        name: null,
        pdf: null
    });
    const {currentCase, setActivePage, updateTimeline} = useContext(CaseContext);

    // Updates timeline as well as set active page
    const nextPage = () => {
        setActivePage("Report");
        updateTimeline("Report", true);
    }

    // Set current pdf and open modal
    const openPdf = (name, pdf) => {
        setSelectedPdf({name: name, pdf: pdf});
        setModalIsOpen(true);
    }

    // Open modal
    const openModal = () => {
        setModalIsOpen(true);
    }

    // Close modal
    const closeModal = () => {
        setSelectedPdf({name: null, pdf: null});
        setModalIsOpen(false);
    }

    return (
        <>
            {currentCase.activePage === "Agreement" &&
            <div className="d-flex flex-column">
                <div>
                    <h2 className="d-inline">Message From Energera Inc.</h2>
                    <p className="mt-3">
                        Energera Inc. (formerly Frac Shack Inc.) is incredibly proud to be voted one of Canada's Best
                        Managed Companies every year since 2018. At Energera we are dedicated to all
                        stakeholders, we place the highest value on our relationships. Integrity in all we do,
                        is <strong>IMPERATIVE</strong>
                        – this is evident in our Code of Conduct and our Core Values. Practicing values of
                        high standards is the responsibility of all Energera employees. To help ensure that
                        we practice what we preach, we have established this anonymous and confidential reporting system
                        so that employees can report any concerns they may have about accounting or financial
                        irregularities, breaches in our Code of Conduct or offer ideas and suggestions that may improve
                        the company's operations. Your comments are all taken very seriously. Thank you for making this
                        report.
                    </p>
                    <div>
                        <input type="checkbox" onChange={() => setAcceptance(!acceptance)} defaultChecked={acceptance}/>
                        <label className="agreement-checkbox-text">By checking here, I am agreeing to the above.</label>
                    </div>
                </div>
                <br/>
                {/*<div className="mb-3">*/}
                {/*    <h4>Frac Shack Core Values</h4>*/}
                {/*    <p className="mt-3 agreement-core-values-header">*/}
                {/*        "OUR PURPOSE IS TO BE A LEADER IN A WORLD WHERE ENERGY IS AFFORDABLE, PLENTIFUL, AVAILABLE AND*/}
                {/*        LESS HARMFUL TO THE ENVIRONMENT. WE WANT TO CHANGE THE WORLD OF ENERGY."*/}
                {/*    </p>*/}
                {/*    <div className="agreement-core-values-container">*/}
                {/*        {FracShackCoreValues.values.map((value) => (*/}
                {/*            <CoreValueCard value={value}/>*/}
                {/*        ))}*/}
                {/*    </div>*/}
                {/*</div>*/}

                <h4>The following below have been added here for your reference:</h4>
                <div className="mb-3 agreement-policy-documents">
                    <h5>Policy Documents</h5>
                    <ul>
                        <li className="agreement-document"
                            onClick={() => openPdf("Code Of Business Conduct and Ethics.pdf", `${process.env.PUBLIC_URL}/documents/Code-of-Conduct-and-Ethics.pdf`)}>
                            Code Of Business Conduct and Ethics.pdf
                        </li>
                        <li className="agreement-document"
                            onClick={() => openPdf("Energera Core Values.pdf", `${process.env.PUBLIC_URL}/documents/Energera-Core-Values.pdf`)}>
                            Energera Core Values.pdf
                        </li>
                    </ul>
                </div>

                {selectedPdf.name !== null && selectedPdf.pdf !== null &&
                <Modal className="case-forum-modal-container"
                       isOpen={modalIsOpen}>
                    <div className="modal-header">
                        <h4>
                            {selectedPdf.name}
                        </h4>
                        <FontAwesomeIcon icon={"times"} onClick={closeModal} style={{cursor: "pointer"}}/>
                    </div>
                    <div className="modal-body">
                        <PdfDocument pdf={selectedPdf.pdf}/>
                    </div>
                </Modal>
                }

                <div className="mt-3">
                    <button type="button"
                            className="btn btn-outline-primary"
                            disabled={!acceptance}
                            onClick={() => nextPage()}>
                        Continue
                    </button>
                </div>
            </div>
            }
        </>
    )
}

export default Agreement;