import React, {useContext, useEffect, useState} from "react";
import {AccessType} from "./AccessControlService";
import {AuthContext} from "../Contexts/AuthContext";

const AccessControl = ({children, renderNoAccess, accessTypes}) => {
    const [permitted, setPermitted] = useState(false)
    const {auth} = useContext(AuthContext);

    useEffect(() => {
        const fetchPermissions = accessTypes =>
            accessCheckForMultiples(accessTypes);
        fetchPermissions(accessTypes);
    }, [auth.access]);

    const accessCheckForMultiples = accessTypes =>
        accessTypes.forEach(type => {
            if (accessCheck(type))
                setPermitted(true);
        });
    
    const accessCheck = accessType => {
        switch (accessType) {
            case AccessType.IsLegalCounsel:
                return auth.access === "LegalCounsel"
            case AccessType.IsExecutiveAssistant:
                return auth.access === "ExecutiveAssistant"
            case AccessType.IsChairPerson:
                return auth.access === "ChairPerson"
            case AccessType.IsItAdmin:
                return auth.access === "ItAdmin"
            case AccessType.IsUser:
                return auth.access === "User"
        }
    };

     if (permitted === true)
        return children;
    
    return renderNoAccess();
};

AccessControl.defaultProps = {
    renderNoAccess: () => null
};

export default AccessControl;