import React, {useContext} from "react";
import {AdminContext} from "./Contexts/AdminContext";
import WrittenQuestionDisplay from "./WrittenQuestionDisplay";

function CaseReport(props) {
    return (
        <div className="case-subpage-container"> 
           <WrittenQuestionDisplay question={"User response:"} response={props.report}/>
        </div>
    )
}

export default CaseReport;