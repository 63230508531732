import React, {Fragment} from 'react';
import {Link} from 'react-router-dom';
import './error.css'
import {Container} from 'reactstrap';

const Error = ({
                   title = 'Oops',
                   children = 'Something went wrong and our developers are on the case. Feel free to let us know what went wrong by reporting the issue.',
                   path = '/',
                   pathText = 'Retry',
                   useStaticLink
               }) => {

    const reloadPage = () => {
        if (pathText.toLowerCase() === 'retry') {
            window.location.reload();
            return false;
        }
    }

    return (
        <div className="error-container">
            <Container className="error-content">
                <h1 className="error-title">{title}</h1>
                <h3 className="error-description">{children}</h3>
                {pathText.length > 0 &&
                <Fragment>
                    {pathText.toLowerCase() === 'retry' &&
                    <div className="error-button" onClick={reloadPage}>
                        <div className="error-button-text">
                            {pathText}
                        </div>
                    </div>
                    }
                    {pathText.toLowerCase() !== 'retry' &&
                    <Fragment>
                        {useStaticLink ?
                            (<a href={path} className="error-button">
                                <div className="error-button-text">
                                    {pathText}
                                </div>
                            </a>) : (
                                <Link to={path} className="error-button">
                                    <div className="error-button-text">
                                        {pathText}
                                    </div>
                                </Link>
                            )
                        }
                    </Fragment>
                    }
                </Fragment>
                }
            </Container>
        </div>
    )
}

export default Error