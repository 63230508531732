import React, {useContext, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelope, faEdit} from "@fortawesome/free-solid-svg-icons";
import AccessControl from "../../Auth/AccessControl";
import {AccessType} from "../../Auth/AccessControlService";
import {faCheck} from "@fortawesome/free-solid-svg-icons/faCheck";
import {faWindowClose} from "@fortawesome/free-solid-svg-icons/faWindowClose";
import {UserAdminContext} from "../../Contexts/UserAdminContext";
import {AuthContext} from "../../Contexts/AuthContext";
import './UserAdmin.css'
import axios from "axios";
import {toast} from "react-toastify";

const UserAdminRow = props => {
    const {properties, sendResetPasswordInvite, setUsers} = useContext(UserAdminContext);
    const [email, setEmail] = useState(null);
    const {auth} = useContext(AuthContext)
    const [optionsDropdownToggle, setOptionsDropdownToggle] = useState(true);
    const toggleOptions = () => {
        setOptionsDropdownToggle(!optionsDropdownToggle);
    };

    const updateEmail = async (e) => {
        e.preventDefault()
        await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/api/Users/change-email-for-user?email=${email}&userId=${props.user.id}`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${sessionStorage.getItem('bearerToken')}`
                }
            })
            .then(response => {
                if (response.status === 200) {
                    let updatedUsers = properties.users;
                    updatedUsers[props.index].email = email
                    setUsers(updatedUsers)
                    toggleOptions()
                    toast.success(`Successfully updated email`, {
                        position: toast.POSITION.BOTTOM_CENTER
                    });
                } else {
                    toast.error("An unexpected error has occured. Please try again later. If the problem persists contact support for further assistance.", {
                        position: toast.POSITION.BOTTOM_CENTER
                    })
                }
            }).catch(() => {
                toast.error("An unexpected error has occured. Please try again later. If the problem persists contact support for further assistance.", {
                    position: toast.POSITION.BOTTOM_CENTER
                })
            })
    }
    
    return (
        <>
            <tr>
                <th scope="row">{props.index + 1}</th>
                <td>{props.user.userName}</td>
                {props.user.email ? <td>{props.user.email}</td> : <td>No Email Provided</td>}
                <td>{props.user.userRoles[0].role.name.replace(/([A-Z])/g, ' $1').trim()}</td>
                <td style={{display: "flex"}}>
                    <button type="button" className="btn btn-outline-primary    "
                            disabled={props.user.email == null}
                            style={{"margin-left": "10px", "margin-right": "10px",}}
                            onClick={() => (sendResetPasswordInvite(props.user.email))}>
                        <span>Reset Password</span>
                        <span>              </span>
                        <FontAwesomeIcon icon={faEnvelope}/>
                    </button>
                    <AccessControl
                        accessTypes={[
                            AccessType.IsItAdmin,
                            AccessType.IsExecutiveAssistant
                        ]}
                        renderNoAccess={() => ""}
                    >
                        <>
                            {optionsDropdownToggle ?
                                <button type="button" disabled={auth.userId === props.user.id}
                                        className="btn btn-outline-info "
                                        onClick={() => toggleOptions()}

                                >
                                    <span>Modify Email</span>
                                    <span>              </span>
                                    <FontAwesomeIcon icon={faEdit}/>

                                </button> :
                                <>
                                    <form onSubmit={updateEmail}>
                                        <div style={{width: "200%"}}>
                                            <input type="email"
                                                   className="form-control-sm"
                                                   id="emailInput"
                                                   placeholder="name@example.com"
                                                   onChange={e => {
                                                       setEmail(e.target.value)
                                                   }}
                                                   required
                                                   style={{width: '75%'}}
                                            />
                                            <button type="submit" className="btn btn-outline-success"
                                                    style={{"margin-left": "10px", "margin-right": "10px",}}
                                            >
                                                <FontAwesomeIcon icon={faCheck}/>
                                            </button>
                                            <button type="button" className="btn btn-outline-danger"
                                                    style={{"margin-left": "10px", "margin-right": "10px",}}
                                                    onClick={() => toggleOptions()}>
                                                <FontAwesomeIcon icon={faWindowClose}/>
                                            </button>
                                        </div>
                                    </form>
                                </>
                            }
                        </>
                    </AccessControl>
                </td>
            </tr>
        </>
    );
};
export default UserAdminRow;
