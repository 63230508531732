import React, {Component} from "react";
import {AdminContext} from "../Contexts/AdminContext";
import axios from "axios";
import {serialize} from "object-to-formdata";
import {toast} from "react-toastify";

export class AdminProvider extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cases: [],
            currentCase: {
                case: null,
                comments: [],
                attachments: [],
                forumAttachments: []
            },
            activeFilter: true,
            dateFilter: {
                startDate: new Date(new Date(Date.now()).setFullYear(new Date(Date.now()).getFullYear() - 1)),
                endDate: new Date(new Date(Date.now()).setHours(23,59,59,999))
            }
        };
    }

    // API call to update currentCase
    updateCurrentCase = async () => {
        await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/api/Case/get-case/${this.state.currentCase.case.caseId}`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${sessionStorage.getItem('bearerToken')}`
                }
            })
            .then(response => {
                if (response.status === 200) {
                    // Update current case
                    this.setState({
                        currentCase: {
                            ...this.state.currentCase,
                            case: response.data.case
                        }
                    })
                } else {
                    toast.error("An unexpected error has occured. Please try again later. If the problem persists contact support for further assistance.", {
                        position: toast.POSITION.BOTTOM_CENTER
                    })
                }
            }).catch(() => {
                // ignore for now
                toast.error("An unexpected error has occured. Please try again later. If the problem persists contact support for further assistance.", {
                    position: toast.POSITION.BOTTOM_CENTER
                })
            })
    }

    // Call API to get all cases
    getCases = async () => {
        await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/api/Case/all-cases`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${sessionStorage.getItem('bearerToken')}`
                }
            })
            .then(response => {
                if (response.status === 200) {
                    this.setState({cases: response.data.cases})
                }else{
                    toast.error("An unexpected error has occured. Please try again later. If the problem persists contact support for further assistance.", {
                        position: toast.POSITION.BOTTOM_CENTER
                    })
                }
            }).catch(() => {
                toast.error("An unexpected error has occured. Please try again later. If the problem persists contact support for further assistance.", {
                    position: toast.POSITION.BOTTOM_CENTER
                })
            })
    }

    // Call API to get active cases
    getActiveCases = async () => {
        return await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/api/Case/active-cases`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${sessionStorage.getItem('bearerToken')}`
                }
            })
            .then(response => {
                if (response.status === 200) {
                    return response.data.cases;
                } else if (response.status === 204) {
                    // No Content
                    // Pass
                }
                else{
                    toast.error("An unexpected error has occured. Please try again later. If the problem persists contact support for further assistance.", {
                        position: toast.POSITION.BOTTOM_CENTER
                    })
                }
            }).catch(() => {
                toast.error("An unexpected error has occured. Please try again later. If the problem persists contact support for further assistance.", {
                    position: toast.POSITION.BOTTOM_CENTER
                })
            })
    }

    // Call API to get active cases
    getInactiveCases = async () => {
        return await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/api/Case/inactive-cases`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${sessionStorage.getItem('bearerToken')}`
                }
            })
            .then(response => {
                if (response.status === 200) {
                    return response.data.cases;
                } else if (response.status === 204) {
                  // No Content
                  // Pass  
                } else{
                    toast.error("An unexpected error has occured. Please try again later. If the problem persists contact support for further assistance.", {
                        position: toast.POSITION.BOTTOM_CENTER
                    })
                }
            }).catch(() => {
                toast.error("An unexpected error has occured. Please try again later. If the problem persists contact support for further assistance.", {
                    position: toast.POSITION.BOTTOM_CENTER
                })
            })
    }

    // API call to get case attachments
    getAttachments = async () => {
        await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/api/Attachment/get-attachments-for-case/${this.state.currentCase.case.caseId}`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${sessionStorage.getItem('bearerToken')}`
                }
            })
            .then(response => {
                if (response.status === 200) {
                    this.setState({
                        currentCase: {
                            ...this.state.currentCase,
                            attachments: response.data.attachments
                        }
                    });
                } else if (response.status === 204) {
                    // No Content
                    // Pass
                } else {
                    toast.error("An unexpected error has occured. Please try again later. If the problem persists contact support for further assistance.", {
                        position: toast.POSITION.BOTTOM_CENTER
                    })
                }
            }).catch(() => {
                toast.error("An unexpected error has occured. Please try again later. If the problem persists contact support for further assistance.", {
                    position: toast.POSITION.BOTTOM_CENTER
                })
            })
    }

    // API call to get case forum attachments
    getForumAttachments = async () => {
        await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/api/Attachment/get-forum-attachments-for-case/${this.state.currentCase.case.caseId}`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${sessionStorage.getItem('bearerToken')}`
                }
            })
            .then(response => {
                if (response.status === 200) {
                    this.setState({
                        currentCase: {
                            ...this.state.currentCase,
                            forumAttachments: response.data.attachments
                        }
                    });
                } else {
                    toast.error("An unexpected error has occured. Please try again later. If the problem persists contact support for further assistance.", {
                        position: toast.POSITION.BOTTOM_CENTER
                    })
                }
            }).catch(() => {
                toast.error("An unexpected error has occured. Please try again later. If the problem persists contact support for further assistance.", {
                    position: toast.POSITION.BOTTOM_CENTER
                })
            })
    }

    // API call to get forum comments
    getComments = async () => {
        await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/api/ForumComments/get-forum-comments-for-a-case/${this.state.currentCase.case.caseId}`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${sessionStorage.getItem('bearerToken')}`
                }
            })
            .then(response => {
                if (response.status === 200) {
                    if(response.data.result === 'Forum Comments Returned Successfully')
                    this.setState({
                        currentCase: {
                            ...this.state.currentCase,
                            comments: response.data.forumComments
                        }
                    });
                }
                else {
                    toast.error("An unexpected error has occured. Please try again later. If the problem persists contact support for further assistance.", {
                        position: toast.POSITION.BOTTOM_CENTER
                    })
                }
            })
    }

    // API call to create forum comment
    createComment = async (comment) => {
        await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/api/ForumComments/create-forum-comment/`,
            serialize(comment),
            {
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: `Bearer ${sessionStorage.getItem('bearerToken')}`
                }
            })
            .then(response => {
                if (response.status === 200) {
                    this.setState({
                        currentCase: {
                            ...this.state.currentCase,
                            comments: [...this.state.currentCase.comments, comment]
                        }
                    })
                } else {
                    toast.error("An unexpected error has occured. Please try again later. If the problem persists contact support for further assistance.", {
                        position: toast.POSITION.BOTTOM_CENTER
                    })
                }
            }).catch((e) => {
                
            })
    }

    // API call to create case status
    createStatus = async (comment) => {
        await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/api/Status/create-new-status/${this.state.currentCase.case.caseId}`,
            JSON.stringify(comment),
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${sessionStorage.getItem('bearerToken')}`
                }
            })
            .then(response => {
                if (response.status === 200) {
                    toast.success('Case status updated successfully', {
                        position: toast.POSITION.BOTTOM_CENTER
                    })
                } else {
                    toast.error("An unexpected error has occured. Please try again later. If the problem persists contact support for further assistance.", {
                        position: toast.POSITION.BOTTOM_CENTER
                    })
                }
            }).catch(() => {
                toast.error("An unexpected error has occured. Please try again later. If the problem persists contact support for further assistance.", {
                    position: toast.POSITION.BOTTOM_CENTER
                })
            })
    }

    // Set current viewing case
    setCurrentCase = (value) => {
        this.setState({
            currentCase: {
                case: value,
                comments: [],
                attachments: [],
                forumAttachments: []
            }
        });
    }
    
    // Get date range
    getDateFilter = () => {
        return [this.state.dateFilter.startDate, this.state.dateFilter.endDate];
    }
    
    // Set new date range
    setDateFilter = (dateRange) => {
        this.setState({
            dateFilter: {
                startDate: dateRange[0],
                endDate: dateRange[1]
            }
        })
    }
    
    // Toggle active filter
    toggleActiveFilter = async () => {
        this.setState({activeFilter: !this.state.activeFilter});
        this.setCurrentCase(null);
    }

    render() {
        return (
            <AdminContext.Provider
                value={{
                    properties: this.state,
                    setCurrentCase: this.setCurrentCase,
                    toggleActiveFilter: this.toggleActiveFilter,
                    setDateFilter: this.setDateFilter,
                    getDateFilter: this.getDateFilter,
                    createComment: this.createComment,
                    getComments: this.getComments,
                    getAttachments: this.getAttachments,
                    getForumAttachments: this.getForumAttachments,
                    updateCurrentCase: this.updateCurrentCase,
                    createStatus: this.createStatus,
                    getActiveCases: this.getActiveCases,
                    getInactiveCases: this.getInactiveCases,
                }}
            >
                {this.props.children}
            </AdminContext.Provider>
        )
    }
}