import React, {useContext, useEffect, useState} from "react";
import AccessControl from "../../Auth/AccessControl";
import {AccessType} from "../../Auth/AccessControlService";
import {Link} from "react-router-dom";
import {AuthContext} from "../../Contexts/AuthContext";
import DetermineUserType from "../../Auth/DetemineUserType";
import Unauthorized from "../Unauthorized/Unauthorized";
import axios from "axios";
import {toast} from "react-toastify";
import {HeaderContext} from "../../Contexts/HeaderContext";

const Home = () => {
    const {auth, setAccess} = useContext(AuthContext);
    const [userCase, setUserCase] = useState(null);
    const {setShowExistingCaseButton} = useContext(HeaderContext)

    const getCase = async () => {
        await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/api/Case/get-case/${auth.caseId}`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${sessionStorage.getItem('bearerToken')}`
                }
            })
            .then(response => {
                if (response.status === 200) {
                    if(response.data.case.report)
                        sessionStorage.setItem('case', JSON.stringify(response.data.case))
                    setUserCase(response.data.case.report)
                    setShowExistingCaseButton(true)
                }
                else if (response.status === 204){
                    // No Content
                }else{
                    toast.error("An unexpected error has occured. Please try again later. If the problem persists contact support for further assistance.", {
                        position: toast.POSITION.BOTTOM_CENTER
                })}});
    }
    useEffect(() => {
        if (auth.bearerToken !== null)
            DetermineUserType(`${process.env.REACT_APP_API_ENDPOINT}/api/`)
                .then(access => {
                    setAccess(access);
                    if (access === "User")
                        getCase()
                });
    }, []);

    return (
        <>
            {auth.bearerToken === null &&
            <Unauthorized/>
            }
            {auth.bearerToken && (
                <main className="form-signin">
                    <h1 style={{textAlign: "center"}} className="h3 mb-3 fw-normal">What would you like to do? </h1>
                    <AccessControl
                        accessTypes={[
                            AccessType.IsUser
                        ]}
                        renderNoAccess={() => ""}
                    >
                        {userCase ?
                            <Link to='/existing-case' className="w-100 btn btn-lg btn-outline-success"
                                  style={{marginBottom: "20px"}}> View Existing Case
                            </Link>
                            :
                            <Link to='/new-case' className="w-100 btn btn-lg btn-outline-success"
                                  style={{marginBottom: "20px"}}>Submit a Report</Link>
                        }
                        <br/>
                    </AccessControl>
                    <AccessControl
                        accessTypes={[
                            AccessType.IsLegalCounsel,
                            AccessType.IsChairPerson
                        ]}
                        renderNoAccess={() => ""}
                    >
                        <Link to='/admin' className="w-100 btn btn-lg btn-outline-success"
                              style={{marginBottom: "20px"}}>View All Cases</Link>
                    </AccessControl>
                    <AccessControl
                        accessTypes={[
                            AccessType.IsLegalCounsel,
                            AccessType.IsChairPerson,
                            AccessType.IsExecutiveAssistant,
                            AccessType.IsItAdmin
                        ]}
                        renderNoAccess={() => ""}
                    >
                        <Link type="button" className="w-100 btn btn-lg btn-outline-success" to='/users-admin'
                              style={{"margin-bottom": "20px"}}>Users
                            Admin Page</Link>
                    </AccessControl>
                </main>
            )}
        </>
    )
};

export default Home;