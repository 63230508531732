import React, {useContext, useEffect, useState} from 'react';
import {AdminContext} from "./Contexts/AdminContext";
import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Calendar from "react-calendar";
import Case from "./Case";
import NoCases from "./Pages/NoCases/NoCases";
import {Tooltip} from "reactstrap";
import moment from "moment";
import 'react-calendar/dist/Calendar.css';
import "./Pages/CasePage/case.css";

function CasesSidebar(props) {
    const {properties, setDateFilter, toggleActiveFilter} = useContext(AdminContext);
    const [isCalenderOpen, setIsCalenderOpen] = useState(false);
    
    const toggle = () => setIsCalenderOpen(!isCalenderOpen);

    const onCalenderChange = (calenderRange) => {
        setDateFilter(calenderRange);

        // Close calendar
        setIsCalenderOpen(false);
    }

    return (
        <div className="d-flex flex-column w-25">
            <BootstrapSwitchButton
                className="case-toggle"
                checked={properties.activeFilter}
                onlabel="Active"
                offlabel="Inactive"
                onChange={() => toggleActiveFilter()}
            />
            {props.dateRange !== null &&
            <div className="date-filter-container">
                <div className="date-filter-date-container">
                    <div className="date-filter-date">{moment(props.dateRange[0]).format("LL")}</div>
                    <div className="date-filter-date">{moment(props.dateRange[1]).format("LL")}</div>
                </div>
                <div className="date-filter-icon" id="calender-icon" onClick={toggle}>
                    <FontAwesomeIcon icon="calendar-alt"/>
                </div>
                <Tooltip className="category-tooltip"
                         placement="right"
                         isOpen={isCalenderOpen}
                         target="calender-icon">
                    <div className="d-flex flex-md-column align-items-start">
                        <Calendar onChange={onCalenderChange} value={props.dateRange} returnValue={"range"}
                                  selectRange={true}/>
                    </div>
                </Tooltip>

            </div>
            }
            <div className="list-group">
                {props.cases.length <= 0 &&
                <NoCases/>
                }
                {props.cases.length > 0 && props.cases.map((x, i) => (
                    <Case key={i} case={x}/>
                ))}
            </div>
        </div>
    )
}

export default CasesSidebar;