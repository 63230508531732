import React, {useContext} from "react";
import "../CasePage/case.css";
import {UserAdminContext} from "../../Contexts/UserAdminContext";
import UserAdminRow from "./UserAdminRow";

const UserAdmin = () => {
    const {properties} = useContext(UserAdminContext);
    return (
        <>
            <h1>User Administration Page</h1>
            {properties &&
            <table className="table" style={{margin: "50px", width: "95%"}}>
                <thead>
                <tr>
                    <th scope="col">#</th>
                    <th scope="col">User Name</th>
                    <th scope="col">Email</th>
                    <th scope="col">Role</th>
                    <th scope="col"/>
                </tr>
                </thead>
                <tbody>
                {properties.users && properties.users.map((user, index) =>
                    <UserAdminRow
                        index={index}
                        user={user}
                    />
                )}
                </tbody>
            </table>
            }
        </>

    )
};

export default UserAdmin;