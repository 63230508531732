import React, {useEffect, useState} from "react";

function WrittenQuestionDisplay(props) {
    return (
        <div className="margin-top-bottom h-100">
            <p className="question-text">{props.question}</p>
            <div className="w-100 text-area-auto">{props.response !== "" ? props.response : "No Response"}</div>
        </div>
    )
}

export default WrittenQuestionDisplay;