import React from "react";

function CheckBoxQuestionDisplay(props) {
    
    // Todo: Fix janky fix for checkbox default check
    
    return (
        <div className="margin-top-bottom">
            <p className="question-text">{props.question}</p>
            <div>
                
                {props.response &&
                <input type="checkbox" disabled={true} defaultChecked={props.response}/>
                }
                {!props.response &&
                <input type="checkbox" disabled={true} defaultChecked={props.response}/>
                }
                <label className="agreement-checkbox-text">{props.message}</label>
            </div>
        </div>
    )
}

export default CheckBoxQuestionDisplay;