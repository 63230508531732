import React, {useEffect, useMemo, useState} from "react";
import {Document, Page, pdfjs} from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";

function PdfDocument(props) {
    const [numPages, setNumPages] = useState(null);
    const pdf = useMemo(() => ({props}), [props.data, props.pdf])
    
    useEffect(() => {
        pdfjs.GlobalWorkerOptions.workerSrc = `${process.env.PUBLIC_URL}/js/pdf.worker.js`;
    }, [])
    
    const onDocumentLoadSuccess = ({numPages}) => {
        setNumPages(numPages);
    }
    
    return (
        <div>
            {props.data !== undefined &&
            <Document
                file={{data: pdf.props.data}}
                onLoadSuccess={onDocumentLoadSuccess}
            >
                {Array.from(new Array(numPages), (el, index) => (
                    <Page key={`page_${index + 1}`} pageNumber={index + 1} />
                ))}
            </Document>
            }
            {props.pdf !== undefined &&
            <Document
                file={props.pdf}
                onLoadSuccess={onDocumentLoadSuccess}
            >
                {Array.from(new Array(numPages), (el, index) => (
                    <Page key={`page_${index + 1}`} pageNumber={index + 1} />
                ))}
            </Document>
            }
        </div>
    )
}

export default PdfDocument;