import React, {useState, useContext, useEffect} from "react";
import Timeline from "../../Timeline/Timeline";
import Report from "../Report/Report";
import "./newcase.css";
import Agreement from "../Agreement/Agreement";
import Category from "../Category/Category";
import AdditionalInformation from "../AddtionalInformation/AdditionalInformation";
import {CaseContext} from "../../Contexts/CaseContext";
import Submit from "../Submit/Submit";
import {AuthContext} from "../../Contexts/AuthContext";

function NewCase(props) {
    const {auth} = useContext(AuthContext);
    const {currentCase, setCaseId} = useContext(CaseContext)
    
    // Set case id 
    useEffect(() => {
        setCaseId(auth.caseId);
    }, [auth.caseId])

    return (
        <div className="new-case-container">
            <div className="new-case-info-body">
                <Agreement/>
                <Report/>
                <Category/>
                <AdditionalInformation/>
                <Submit/>
            </div>
            <div className="new-case-footer">
                <Timeline steps={currentCase.steps}/>
            </div>
        </div>
    )
}

export default NewCase;