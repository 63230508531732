import React, {useContext, useState, useEffect} from "react";
import {CaseContext} from "./Contexts/CaseContext";
import {Tooltip} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function CheckBoxQuestion(props) {
    const {currentCase, updateLegalCounsel} = useContext(CaseContext);
    const [toolTipOpen, setToolTipOpen] = useState(false);

    // Init
    useEffect(() => {
        saveShallowCopy(currentCase.isLegalCounsel);

        if (props.required) {
            updateRequiredQuestion(false);
        }
    }, [])

    // On response refresh
    useEffect(() => {
        if (props.response[props.index] === undefined) {
            saveShallowCopy(currentCase.isLegalCounsel);
        }
        
    }, [props.response, props.index])

    const toggle = () => setToolTipOpen(!toolTipOpen);

    // Handles changes to checkbox state
    const handleChange = () => {
        saveShallowCopy(!currentCase.isLegalCounsel);
        updateLegalCounsel();

        if (props.required && props.response[props.index].response !== null && props.response[props.index].response !== undefined) {
            updateRequiredQuestion(true);
        } else if (props.required && (props.response[props.index].response === null || props.response[props.index].response === undefined)) {
            updateRequiredQuestion(false);
        }
    }

    // Create a shallow copy of response
    const saveShallowCopy = (value) => {
        // Create shallow copy
        let responses = Object.assign({}, props.response)

        // Replace old response with new response
        responses[props.index] = {
            question: props.question,
            response: value,
            message: props.message,
            type: "checkbox"
        };

        // Update parent state
        props.setResponse(responses);
    }

    // Create a shallow copy of requiredQuestion
    const updateRequiredQuestion = (value) => {
        // Create shallow copy
        let requiredQuestion = Object.assign({}, props.requiredQuestion);

        // Replace old response with new response
        requiredQuestion[props.index] = {
            question: props.question,
            answered: value
        };

        // Update parent state
        props.setRequiredQuestion(requiredQuestion);
    }

    return (
        <div className={`question-container ${props.required ? "required" : ""}`}>
            <div>
                <div className="question-text">{props.question}</div>
                {/*<div id={`${props.index}`}>*/}
                {/*    <FontAwesomeIcon icon="info-circle"/>*/}
                {/*</div>*/}
                {/*<Tooltip className="question-tooltip"*/}
                {/*         placement="right"*/}
                {/*         isOpen={toolTipOpen}*/}
                {/*         toggle={toggle}*/}
                {/*         target={`${props.index}`}>*/}
                {/*    <div className="d-flex flex-md-column align-items-start">*/}
                {/*        <h5>{props.tooltipHeader}</h5>*/}
                {/*        <div>{props.tooltip}</div>*/}
                {/*    </div>*/}
                {/*</Tooltip>*/}
            </div>
            <div>
                <input type="checkbox" onChange={() => handleChange()} defaultChecked={currentCase.isLegalCounsel}
                       required={props.required}/>
                <label className="agreement-checkbox-text">{props.message}</label>
            </div>
        </div>
    )
}

export default CheckBoxQuestion;