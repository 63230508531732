import React, {useEffect, useState, useContext} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import cn from "classnames";
import axios from "axios";
import {toast} from "react-toastify";
import usePrevious from "./CustomHooks/usePrevious";
import Pagination from "./Pagination";
import "react-toastify/dist/ReactToastify.css";
import Modal from "react-modal";
import NoAttachments from "./Pages/NoAttachments/NoAttachments";
import PdfDocument from "./PdfDocument";
import Base64ToArrayBuffer from "../Helpers/Base64ToArrayBuffer";

function CaseAttachments(props) {
    // Configure package imports
    toast.configure()

    // States
    const prevCaseId = usePrevious(props.currentCase.case.caseId);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [activeAttachments, setActiveAttachments] = useState([]);
    const [selectedAttachment, setSelectedAttachment] = useState(null);
    const [selectedAttachmentObj, setSelectedAttachmentObj] = useState({
        fileName: null,
        fileNameShort: null,
        url: null,
        byteArray: null,
        contentType: null
    });

    const openModal = (x) => {
        setModalIsOpen(true);
    }

    const closeModal = () => {
        setSelectedAttachmentObj({
            fileName: null,
            fileNameShort: null,
            url: null,
            byteArray: null,
            contentType: null
        });
        setModalIsOpen(false);
    }

    useEffect(() => {
        Modal.setAppElement("body");
    }, [])

    useEffect(() => {
        if (props.currentCase.case !== null && props.currentCase.case.caseId !== prevCaseId) {
            setSelectedAttachment(null);
            setSelectedAttachmentObj({
                fileName: null,
                fileNameShort: null,
                url: null,
                byteArray: null,
                contentType: null
            });

            props.getAttachments();
            // props.getForumAttachments();
        }
    }, [props.currentCase])

    // Api call to retrieve url from SAS
    const getUrl = async (fileName) => {
        return await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/api/Attachment/get-attachment-by-name`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${sessionStorage.getItem('bearerToken')}`
                },
                params: {
                    name: fileName
                }
            })
            .then(response => {
                if (response.status === 200) {
                    setSelectedAttachmentObj({
                        fileName: fileName,
                        fileNameShort: response.data.fileNameShort,
                        url: response.data.url,
                        byteArray: response.data.byteArray,
                        contentType: response.data.contentType
                    })
                    return {
                        url: response.data.url,
                        byteArray: response.data.byteArray,
                        contentType: response.data.contentType
                    };
                } else if (response.status === 204) {
                    // No content
                    // pass  
                } else {
                    toast.error("An unexpected error has occured. Please try again later. If the problem persists contact support for further assistance.", {
                        position: toast.POSITION.BOTTOM_CENTER
                    })
                }
            }).catch(() => {
                toast.error("An unexpected error has occured. Please try again later. If the problem persists contact support for further assistance.", {
                    position: toast.POSITION.BOTTOM_CENTER
                })
            })
    }

    const createDownloadLink = async (filename, url, byteArray, contentType) => {
        var bytes = Base64ToArrayBuffer(byteArray);
        var blob = new Blob([bytes], {type: contentType});

        const link = document.createElement('a');
        const fileName = filename.substring(filename.indexOf("/") + 1);

        link.href = window.URL.createObjectURL(blob);
        link.setAttribute("download", `${fileName}`);

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        document.body.removeChild(link);
    }

    const download = async (filename) => {
        // Fetch blob url and set local state
        const selectObj = await getUrl(filename);
        await createDownloadLink(filename, selectObj.url, selectObj.byteArray, selectObj.contentType);
    }

    const setEmbeddedAttachment = async (attachment) => {
        await getUrl(attachment.fileName);
        setSelectedAttachment(attachment);
    }

    return (
        <div className="case-subpage-container">
            <div className="case-attachment-table-container">
                <table className="table">
                    <thead className="thead-light">
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">Filename</th>
                        {/*<th scope="col">Upload User</th>*/}
                        <th scope="col">Download</th>
                    </tr>
                    </thead>
                    <tbody>
                    {activeAttachments.length > 0 && activeAttachments.map((x, i) => (
                        <tr className={cn("", {"selected-attachment": selectedAttachment && selectedAttachment.id === x.id})}
                            onClick={() => setEmbeddedAttachment(x)}>
                            <th scope="row">{i + 1}</th>
                            <td className="selected-attachment-file" onClick={() => openModal(x)}>{x.fileNameShort}</td>
                            {/*<td>{x.userId}</td>*/}
                            <td>
                                <button type="button" className="btn btn-primary" style={{backgroundColor: "grey"}}
                                        onClick={() => download(x.fileName)}>
                                    <FontAwesomeIcon icon={"download"}/>
                                </button>
                            </td>
                        </tr>
                    ))}
                    {activeAttachments.length <= 0 &&
                    <tr>
                        <td colSpan="4">
                            <NoAttachments/>
                        </td>
                    </tr>
                    }
                    </tbody>
                </table>
            </div>

            <Pagination items={props.currentCase.attachments} setActiveAttachments={setActiveAttachments}/>

            {selectedAttachmentObj && selectedAttachmentObj.fileName !== undefined && selectedAttachmentObj.url !== null && selectedAttachmentObj.contentType !== null && selectedAttachmentObj.byteArray !== null &&
            <Modal className="case-forum-modal-container"
                   isOpen={modalIsOpen}>
                <div className="modal-header">
                    <h4>
                        {selectedAttachmentObj.fileNameShort}
                    </h4>
                    <FontAwesomeIcon icon={"times"} onClick={closeModal} style={{cursor: "pointer"}}/>
                </div>
                <div className="modal-body">
                    {selectedAttachmentObj.contentType.split("/")[0] === "image" &&
                    <embed className="modal-attachment" src={selectedAttachmentObj.url}/>
                    }
                    {selectedAttachmentObj.contentType.split("/")[0] === "video" &&
                    <video className="modal-attachment" src={selectedAttachmentObj.url} controls/>
                    }
                    {selectedAttachmentObj.contentType === "application/pdf" &&
                    <PdfDocument data={Base64ToArrayBuffer(selectedAttachmentObj.byteArray)}/>
                    }
                </div>
            </Modal>
            }
        </div>
    )
}

export default CaseAttachments;