import React, {useEffect, useState} from "react";
import cn from "classnames";

const ATTACHMENTS_PER_PAGE = 5;

const paginationStep = {
    First: "first",
    Next: "next",
    Skip: "skip",
    Previous: "previous",
    Last: "last"
}

function Pagination(props) {
    const [totalPages, setTotalPages] = useState(null);
    const [activePage, setActivePage] = useState(1);
    const [indexOfLastAttachment, setIndexOfLastAttachment] = useState(activePage * ATTACHMENTS_PER_PAGE);
    const [indexOfFirstAttachment, setIndexOfFirstAttachment] = useState(indexOfLastAttachment - ATTACHMENTS_PER_PAGE);
    const [pageNumbers, setPageNumbers] = useState(new Set());

    useEffect(() => {
        if (props.items) {
            for (let i = 1; i <= Math.ceil(props.items.length / ATTACHMENTS_PER_PAGE); i++) {
                pageNumbers.add(i)
            }

            props.setActiveAttachments(props.items.slice(indexOfFirstAttachment, indexOfLastAttachment));
        }

        let pages = Math.ceil(props.items.length / ATTACHMENTS_PER_PAGE);
        setTotalPages(pages);
    }, [props.items])

    useEffect(() => {
        const indexOfLastAttachment = activePage * ATTACHMENTS_PER_PAGE;
        const indexOfFirstAttachment = indexOfLastAttachment - ATTACHMENTS_PER_PAGE;
        const currentAttachments = props.items.slice(indexOfFirstAttachment, indexOfLastAttachment);

        // Update pagination variables
        setIndexOfLastAttachment(indexOfLastAttachment);
        setIndexOfFirstAttachment(indexOfFirstAttachment);
        props.setActiveAttachments(currentAttachments);
    }, [activePage])

    // Determine what page to switch to depending on type of click
    const onPaginationClick = (action, page) => {
        if (activePage !== page) {
            switch (action) {
                case paginationStep.First:
                    setActivePage(1);
                    return;
                case paginationStep.Next:
                    if (activePage + 1 <= totalPages)
                        setActivePage(activePage + 1);
                    return;
                case paginationStep.Previous:
                    if (activePage - 1 >= 1)
                        setActivePage(activePage - 1);
                    return;
                case paginationStep.Last:
                    setActivePage(totalPages);
                    return;
                case paginationStep.Skip:
                    setActivePage(page);
                    return;
            }
        }

        return null;
    }

    return (
        <>
            {totalPages > 1 &&
            <div className="pagination-bar">
                <div className="pagination-group" style={{marginRight: "auto", marginLeft: "30px", width: "100px"}}>
                    <div className="pagination-element"
                         onClick={() => onPaginationClick(paginationStep.First, 1)}><strong>&lt;&lt;</strong></div>
                    <div className="pagination-element"
                         onClick={() => onPaginationClick(paginationStep.Previous, activePage - 1)}>
                        <strong>&lt;</strong></div>
                </div>
                <div className="pagination-group" style={{flex: "1 1 0"}}>
                    {activePage && [...pageNumbers].map(page => (
                        <div className={cn("pagination-element", {
                            "active-page": activePage === page
                        })} onClick={() => onPaginationClick(paginationStep.Skip, page)}>
                            <>{page}</>
                        </div>
                    ))}
                </div>
                <div className="pagination-group" style={{marginLeft: "auto", marginRight: "30px", width: "100px"}}>
                    <div className="pagination-element"
                         onClick={() => onPaginationClick(paginationStep.Next, activePage + 1)}><strong>&gt;</strong>
                    </div>
                    <div className="pagination-element"
                         onClick={() => onPaginationClick(paginationStep.Last, totalPages)}><strong>&gt;&gt;</strong>
                    </div>
                </div>
            </div>
            }
        </>
    )
}

export default Pagination;