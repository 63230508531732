import React, {Component} from "react";
import {AuthContext} from "../Contexts/AuthContext";

export class AuthProvider extends Component {
    constructor(props) {
        super(props);
        this.state = {
            caseId: null,
            userId: null,
            bearerToken: null,
            access: null,
            userName: null,
            email: null

        };
        // Upon Refresh
        if (window.performance) {
            this.state.bearerToken = sessionStorage.getItem('bearerToken')
            this.state.access = sessionStorage.getItem('access')
            this.state.userId = sessionStorage.getItem('userId')
            this.state.caseId = sessionStorage.getItem('caseId')
            this.state.userName = sessionStorage.getItem('userName')
        }
    }

    // setBearerToken
    setBearerToken = (value) => {
        sessionStorage.setItem('bearerToken', value);
        this.setState({bearerToken: value})
    }

    // removeBearerToken
    removeBearerToken = () => {
        this.setState({bearerToken: null})
        sessionStorage.removeItem('bearerToken')
    }

    // Update case id
    setCaseId = (value) => {
        this.setState({caseId: value});
        sessionStorage.setItem('caseId', value);
    }

    // Update user id
    setUserId = (value) => {
        this.setState({userId: value});
        sessionStorage.setItem('userId', value);
    }

    setAccess = (value) => {
        this.setState({access: value});
        sessionStorage.setItem('access', value)
    }
    
    removeAccess = () => {
        this.setState({access: null})
        sessionStorage.removeItem('access')
    } 
    
    setUserName = (value) => {
        this.setState({userName: value})
        sessionStorage.setItem('userName', value)
    }
    
    removeUserName =() => {
        this.setState({userName: null})
        sessionStorage.removeItem('userName')
    }
    
    setEmail = (value) => {
        this.setState({email: value})
        sessionStorage.setItem('email', value)
    }

    removeEmail =() => {
        this.setState({email: null})
        sessionStorage.removeItem('email')
    }
    
    render() {
        return (
            <AuthContext.Provider
                value={{
                    auth: this.state,
                    setCaseId: this.setCaseId,
                    setUserId: this.setUserId,
                    removeBearerToken: this.removeBearerToken,
                    setBearerToken: this.setBearerToken,
                    setAccess: this.setAccess,
                    removeAccess: this.removeAccess,
                    setUserName: this.setUserName,
                    removeUserName: this.removeUserName,
                    setEmail: this.setEmail,
                    removeEmail: this.removeEmail
                }}
            >
                {this.props.children}
            </AuthContext.Provider>
        )
    }
}