import React from "react";
import './timeline.css';

function Timeline(props) {
    const {steps} = props;
    const totalSteps = Object.keys(steps).length;
    const numberOfActiveSteps = Object.keys(Object.fromEntries(Object.entries(steps).filter(([k,v]) => v === true))).length;
    const progressBarWidth = totalSteps > 1 ? (numberOfActiveSteps - 1) / (totalSteps - 1) * 100 : 0;

    return (
        <div className="timeline">
            <div className="timeline-progress" style={{width: `${progressBarWidth}%`}}/>
            <div className="timeline-items">
                {Object.entries(steps).map(([key, value], i) => (
                    <div key={i} className={"timeline-item" + (value ? ' active' : '')}>
                        <div className="timeline-content">
                            {key}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default Timeline;