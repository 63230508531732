import React, {useState, useRef, useCallback} from "react";
import cn from "classnames";
import {useDropzone} from "react-dropzone";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Input} from "reactstrap";
import Attachment from "./Attachment";

const INITIAL_HEIGHT = 46;

function CommentBox(props) {
    const [isExpanded, setIsExpanded] = useState(false);
    const [commentValue, setCommentValue] = useState("");
    const [attachments, setAttachments] = useState({});
    const maxLength = 1000;
    const outerHeight = useRef(INITIAL_HEIGHT);
    const textRef = useRef(null);
    const containerRef = useRef(null);

    const onDrop = useCallback((acceptedFiles) => {
        acceptedFiles.forEach((file) => {
            const reader = new FileReader()

            reader.onabort = () => console.log('file reading was aborted')
            reader.onerror = () => console.log('file reading has failed')
            reader.onload = () => {

                attachments[file.name] = new Blob([reader.result])
            }
            reader.readAsArrayBuffer(file)
        })

    }, [attachments])

    const {getRootProps, getInputProps, isDragActive} = useDropzone({
        onDrop,
        accept: "image/*, video/*, application/pdf",
        maxFiles: 5
    });

    // Delete attachment
    const deleteAttachment = (key) => {
        const copyAttachments = {...attachments};
        delete copyAttachments[key]
        setAttachments(copyAttachments);
    }

    const onExpand = () => {
        if (!isExpanded) {
            outerHeight.current = containerRef.current.scrollHeight;
            setIsExpanded(true);
        }
    };

    const onChange = (e) => {
        setCommentValue(e.target.value);
        setRemainingCharacters(maxLength - e.target.value.length);

    };

    const onClose = () => {
        setCommentValue("");
        setIsExpanded(false);
    };

    const onSubmit = (e) => {
        e.preventDefault();

        props.submit(commentValue, attachments);
        setCommentValue("");
        setAttachments({});
    }

    const [remainingCharacters, setRemainingCharacters] = useState(maxLength)

    return (
        <form
            onSubmit={onSubmit}
            ref={containerRef}
            className={cn("comment-box", {
                expanded: isExpanded,
                collapsed: !isExpanded,
                modified: commentValue.length > 0
            })}
            style={{
                minHeight: isExpanded ? outerHeight.current : INITIAL_HEIGHT
            }}
        >
            <div className="header">
                <div className="user">
                    <span>You</span>
                </div>
            </div>

            <label htmlFor="comment">What are your thoughts?</label>
            <textarea
                ref={textRef}
                onClick={onExpand}
                onFocus={onExpand}
                onChange={onChange}
                className="comment-field"
                placeholder="What are your thoughts?"
                value={commentValue}
                name="comment"
                id="comment"
                maxLength={maxLength}
            />
            <div style={{width: "100%", display: "flex"}}>
                <span style={{float: "left"}}> <strong>Max Characters Length:</strong> {maxLength} </span>
                <span style={{float: "right", "flex": "1", "text-align": "right", "white-space": "nowrap"}}> <strong>Remaining Characters:</strong> {remainingCharacters}</span>
            </div>

            <div className="comment-box-footer">
                <div className="comment-box-attachments">
                    <div {...getRootProps({className: "dropzone disabled"})}>
                        <input {...getInputProps()} />
                        <div style={{"padding": "5px, 0, 0, 5px", "font-size": "14px"}}>
                            <FontAwesomeIcon className="comment-box-dropzone" icon={"paperclip"}/>
                            <i>Supported Formats: .png, .jpg, .mp4 </i>
                        </div>
                    </div>
                    <div className="comment-box-attachments-container">
                        {Object.entries(attachments).map(([key, value], index) => (
                            <Attachment key={index} name={key} remove={() => deleteAttachment(key)}/>
                        ))}
                    </div>
                </div>
                <div className="actions">
                    <button type="button" className="cancel" onClick={onClose}>
                        Cancel
                    </button>
                    <button type="submit" disabled={commentValue.length < 1 && Object.keys(attachments).length < 1}>
                        Respond
                    </button>
                </div>
            </div>
        </form>
    );
}

export default CommentBox;