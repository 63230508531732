import React, {useContext} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {AuthContext} from "./Contexts/AuthContext";
import cn from "classnames";
import axios from "axios";
import {toast} from "react-toastify";
import moment from "moment";
import "react-toastify/dist/ReactToastify.css";

function ForumComment(props) {
    const {auth} = useContext(AuthContext);
    toast.configure()
    // Api call to get sas url from backend
    const openAttachment = async (fileName) => {
        await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/api/Attachment/get-attachment-by-name?name=${fileName}`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${sessionStorage.getItem('bearerToken')}`
                }
            })
            .then(response => {
                if (response.status === 200) {
                    props.onAttachmentClick(fileName, response.data.url, response.data.contentType, response.data.byteArray);
                } else {
                    toast.error("An unexpected error has occured. Please try again later. If the problem persists contact support for further assistance.", {
                        position: toast.POSITION.BOTTOM_CENTER
                    })
                }
            }).catch(() => {
                toast.error("An unexpected error has occured. Please try again later. If the problem persists contact support for further assistance.", {
                    position: toast.POSITION.BOTTOM_CENTER
                })
            })
    }
    
    return (
        <div className={cn("forum-comment-container card", {
            "is-mine": props.comment.userForumComments[0].user.id === auth.caseId
        })}>
            <div className="forum-comment-header card-title">
                <FontAwesomeIcon icon="user"/>

                {props.comment.userForumComments[0].user.id === auth.caseId &&
                <span id="forum-comment-user-id">You</span>
                }
                {props.comment.userForumComments[0].user.id !== auth.caseId && 
                (
                    <span id="forum-comment-user-id">
                        {props.comment.userForumComments[0].user.email !== null ? 
                            props.comment.userForumComments[0].user.userName : 'Case User'}
                    </span>
                )
                }
            </div>
            <div className="forum-comment-body card-text">
                {props.comment.message}
            </div>
            
            {props.comment.forumAttachments !== undefined && props.comment.forumAttachments.length > 0 &&
                <div className="forum-comment-attachments-container">
                    {props.comment.forumAttachments.map((x, i) => (
                        <div className="forum-comment-attachment" onClick={() => openAttachment(x.fileName)}>
                            <FontAwesomeIcon icon={"paperclip"}/>
                            <span> {x.fileName.split("/")[1]}</span>
                        </div>
                    ))}
                </div>
            }
            
            <div className="forum-comment-footer">
                {moment(props.comment.dateCreated).format('MMMM Do YYYY, h:mm:ss a')}
            </div>
        </div>
    )
}

export default ForumComment;