import React from "react";
import Error from "./Pages/Error/Error";

const NotFound = () => {
    return (
        <Error title={"Nothing Here"}>
            {"Unfortunately, you have requested utter nonsense and we can’t do anything for you"}
        </Error>
    )
}

export default NotFound