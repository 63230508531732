import React from "react";
import Error from "../Error/Error";

function Unauthorized(props) {
    
    return(
        <>
            <Error title="No Go" pathText="">
                {"You do not have permissions to view this page."}
            </Error>
        </>
    )
}

export default Unauthorized;