import React, {useContext, useEffect, useState} from 'react';
import {CaseContext} from "../../Contexts/CaseContext";
import axios from "axios";
import {toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {Link} from "react-router-dom";
import {HeaderContext} from "../../Contexts/HeaderContext";


function Submit() {
    toast.configure()
    const {currentCase, setActivePage, updateTimeline, generateCase, generateAttachments} = useContext(CaseContext);
    const [loading, setLoading] = useState(false);
    const [caseSubmitted, setCaseSubmitted] = useState(false);
    const [uploadCompleted, setUploadCompleted] = useState(false);
    const {setShowExistingCaseButton} = useContext(HeaderContext)


    useEffect(() => {
        if (Object.keys(currentCase.attachments).length > 0) {
            setLoading(false)
            return
        }
        setLoading(false)

    }, [uploadCompleted, caseSubmitted])

    // API call to submit report and redirect to created user homepage
    const submit = async () => {
        await createCase().then(async () => {
                if (Object.keys(currentCase.attachments).length > 0)
                    await uploadAttachments()
            }
        );

    }

    // Api call to create case
    const createCase = async () => {
        setLoading(true)
        await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/api/Case/create-case`,
            generateCase(),
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${sessionStorage.getItem('bearerToken')}`
                }
            })
            .then(response => {
                if (response.status === 200) {
                    toast.success("Case Created Successfully", {
                        position: toast.POSITION.BOTTOM_CENTER
                    })
                    setCaseSubmitted(true)
                    setShowExistingCaseButton(true)
                } else {
                    toast.error("An unexpected error has occured. Please try again later. If the problem persists contact support for further assistance.", {
                        position: toast.POSITION.BOTTOM_CENTER
                    })
                    setCaseSubmitted(true)
                }
            }).catch(() => {
                toast.error("An unexpected error has occured. Please try again later. If the problem persists contact support for further assistance.", {
                    position: toast.POSITION.BOTTOM_CENTER
                })
                setCaseSubmitted(true)
            })
    }

    // Api call to upload attachments to azure blob storage
    const uploadAttachments = async () => {
        setLoading(true)
        await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/api/Attachment/create-new-attachment/false`,
            generateAttachments(),
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${sessionStorage.getItem('bearerToken')}`
                }
            })
            .then(response => {
                if (response.status === 200) {
                    setUploadCompleted(true)
                } else {
                    toast.error("An unexpected error has occured. Please try again later. If the problem persists contact support for further assistance.", {
                        position: toast.POSITION.BOTTOM_CENTER
                    })
                    setUploadCompleted(true)
                }
            }).catch(() => {
                toast.error("An unexpected error has occured. Please try again later. If the problem persists contact support for further assistance.", {
                    position: toast.POSITION.BOTTOM_CENTER
                })
                setUploadCompleted(true)
            })
    }

    // Updates timeline as well as set active page as previous page
    const prevPage = () => {
        setActivePage("Additional Information");
        updateTimeline("Submit", false);
    }

    return (
        <>
            {currentCase.activePage === "Submit" &&
            <main className="form-signin">
                <div>
                    <h1 className="h3 mb-3 fw-normal">Submit Report</h1>
                    <p style={{"text-align": "center"}}>Click the button below to submit your report.</p>
                </div>
                <button type="button" className="w-100 btn btn-lg btn-outline-danger" style={{"margin-bottom": "20px"}} disabled={loading || caseSubmitted}
                        onClick={() => prevPage()}>Back
                </button>
                <button type="button" className="w-100 btn btn-lg btn-outline-primary" disabled={loading || caseSubmitted}
                        style={{"margin-bottom": "20px"}}
                        onClick={() => submit()}>
                    {loading ? "Submitting Case..." : "Submit Case"}
                </button>
                {caseSubmitted && !loading &&
                <Link type="button" to='/existing-case' className="w-100 btn btn-lg btn-outline-primary">View
                    Case</Link>
                }

            </main>
            }
        </>
    )
}

export default Submit;