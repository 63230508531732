import {library} from "@fortawesome/fontawesome-svg-core";
import {
    faInfoCircle,
    faCloudUploadAlt,
    faTrash,
    faUser,
    faPaperclip,
    faSyncAlt,
    faDownload,
    faTimes,
    faSuitcase,
    faPuzzlePiece,
    faFolderOpen,
    faCalendarAlt
} from "@fortawesome/free-solid-svg-icons"

library.add(faInfoCircle, faCloudUploadAlt, faTrash, faUser, faPaperclip, faSyncAlt, faDownload, faTimes, faSuitcase, faPuzzlePiece, faFolderOpen, faCalendarAlt);