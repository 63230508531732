import React, {useContext, useEffect, useState} from "react";
import {CaseContext} from "../../Contexts/CaseContext";
import QuestionsJson from "../../../JsonObjects/Questions.json";
import CheckBoxQuestion from "../../CheckBoxQuestion";
import WrittenQuestion from "../../WrittenQuestion";
import DropdownQuestion from "../../DropdownQuestion";
import "./additionalInformation.css";

function AdditionalInformation(props) {
    const {currentCase, setActivePage, updateTimeline, updateResponse} = useContext(CaseContext);
    const [questions, setQuestions] = useState(null);
    const [response, setResponse] = useState({});
    const [requiredQuestion, setRequiredQuestion] = useState({})
    
    useEffect(() => {
        // Get list of questions based on selected category and sub category
        if (currentCase.selectedCategory !== null && currentCase.selectedSubCategory !== null) {
            const category = getCategory();
            const questions = getQuestions(category);

            // Clear response and set questions
            setResponse({});
            setQuestions(questions);
        }

    }, [currentCase.selectedCategory, currentCase.selectedSubCategory])

    // Checks if all required questions are answered
    const canContinue = () => {
        if (Object.keys(requiredQuestion).length > 0) {
            let unansweredQuestions = 0;
            Object.entries(requiredQuestion).forEach(x => {
                if (!x[1].answered) {
                    unansweredQuestions += 1;
                }
            })
            
            return unansweredQuestions <= 0;
        }
    }

    // Retrieve category from Json Object
    const getCategory = () => {
        return QuestionsJson.categories.filter(x => x.category === currentCase.selectedCategory);
    }

    // Retrieve questions from Json Object 
    const getQuestions = (category) => {
        return category[0].subCategories.filter(x => x.index === currentCase.selectedSubCategory)[0].questions;
    }

    // Updates timeline as well as set active page as next page
    const nextPage = () => {
        setActivePage("Submit");
        updateTimeline("Submit", true);
        
        updateResponse(response);
    }

    // Updates timeline as well as set active page as previous page
    const prevPage = () => {
        setActivePage("Category");
        updateTimeline("Additional Information", false);
    }

    return (
        <>
            {currentCase.activePage === "Additional Information" &&
            <div>
                <div>
                    <h2>Please answer the following questions</h2>
                    <p>Include as much detail as possible. It's OK to skip a question that is not required.</p>
                </div>
                {questions && questions.map((q, i) => {
                    if (q.type === "checkbox") {
                        return <CheckBoxQuestion question={q.question}
                                                 message={q.checkboxMessage}
                                                 tooltip={q.tooltip}
                                                 tooltipHeader={q.tooltipHeader}
                                                 required={q.required}
                                                 response={response}
                                                 setResponse={setResponse}
                                                 requiredQuestion={requiredQuestion}
                                                 setRequiredQuestion={setRequiredQuestion}
                                                 index={i}/>
                    } else if (q.type === "written") {
                        return <WrittenQuestion question={q.question}
                                                tooltip={q.tooltip}
                                                required={q.required}
                                                response={response}
                                                setResponse={setResponse}
                                                requiredQuestion={requiredQuestion}
                                                setRequiredQuestion={setRequiredQuestion}
                                                index={i}/>
                    } else if (q.type === "dropdown") {
                        return <DropdownQuestion question={q.question}
                                                 tooltip={q.tooltip}
                                                 choices={q.choices}
                                                 additionalQuestion={q.additionalQuestion}
                                                 required={q.required}
                                                 response={response}
                                                 setResponse={setResponse}
                                                 requiredQuestion={requiredQuestion}
                                                 setRequiredQuestion={setRequiredQuestion}
                                                 index={i}/>
                    }
                })}

                <div className="d-flex flex-sm-row mt-5">
                    <button type="button" className="btn btn-outline-danger" onClick={() => prevPage()}>Back</button>
                    <button type="button" className="btn btn-outline-primary btn-ml-25"
                            disabled={!canContinue()}
                            onClick={() => nextPage()}>Continue
                    </button>
                </div>
            </div>
            }
        </>
    )
}

export default AdditionalInformation;