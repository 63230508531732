import React, {useContext, useEffect, useState} from "react";
import './Header.css';
import {Link} from "react-router-dom";
import SmallLogo from "../../images/Energera_FullLogo_White.png";
import AccessControl from "../Auth/AccessControl";
import {AccessType} from "../Auth/AccessControlService";
import {AuthContext} from "../Contexts/AuthContext";
import {toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {HeaderContext} from "../Contexts/HeaderContext";


const Header = () => {
    const {auth, removeBearerToken, removeAccess, removeUserName, removeEmail} = useContext(AuthContext)
    const {showExistingCaseButton} = useContext(HeaderContext)
    const onLogout = () => {
        toast.success('Logout Successful', {
            position: toast.POSITION.BOTTOM_CENTER
        })
        removeBearerToken();
        removeAccess();
        removeUserName();
        removeEmail();
        sessionStorage.removeItem('caseId');
        sessionStorage.removeItem('userId');
        sessionStorage.removeItem('case');
    }


    return (
        <>
            <main>
                <nav className="navbar navbar-expand-xl" aria-label="Sixth navbar example">
                    <div className="container-fluid">
                        <div className="smallLogoContainer">
                            {auth.bearerToken === null && (
                                <Link to='/'>
                                    <img src={SmallLogo} className="logo" alt="Energera Logo"/>
                                </Link>)
                            }
                            {auth.bearerToken && (
                                <Link to='/home'>
                                    <img src={SmallLogo} className="logo" alt="Energera Logo" s/>
                                </Link>)
                            }
                        </div>
                        <div className="collapse navbar-collapse" id="navbarsExample06">
                            <div className="col-md-12 text-end">
                                {auth.bearerToken === null && (
                                    <>
                                        <Link type="button" className="btn btn-outline-primary me-2" to='/login'>View Existing Case</Link>
                                        <Link type="button" className="btn btn-outline-primary" to='/register-new-page'>Create New Case</Link>
                                    </>
                                )}
                                {auth.bearerToken && (
                                    <>
                                        <AccessControl
                                            accessTypes={[
                                                AccessType.IsLegalCounsel,
                                                AccessType.IsChairPerson,
                                            ]}
                                            renderNoAccess={() => ""}
                                        >
                                            <Link type="button" className="btn btn-outline-primary" to='/admin'
                                                  style={{"margin-right": "5px"}}>View All Cases</Link>
                                        </AccessControl>
                                        <AccessControl
                                            accessTypes={[
                                                AccessType.IsLegalCounsel,
                                                AccessType.IsChairPerson,
                                                AccessType.IsExecutiveAssistant,
                                                AccessType.IsItAdmin
                                            ]}
                                            renderNoAccess={() => ""}
                                        >
                                            <Link type="button" className="btn btn-outline-primary" to='/users-admin'
                                                  style={{"margin-right": "5px"}}>Users
                                                Admin Page</Link>
                                        </AccessControl>
                                        <Link to='/login' className="btn btn-outline-danger me-2"
                                              onClick={() => onLogout()}>
                                            Logout
                                        </Link>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </nav>
            </main>
        </>
    )
};

export default Header;