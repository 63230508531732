import React, {useContext, useState, useEffect} from "react";
import AccessControl from "./Auth/AccessControl";
import {AccessType} from "./Auth/AccessControlService";
import CaseStatusCard from "./CaseStatusCard";
import TextArea from "./TextArea";
import Modal from "react-modal";

function CaseStatus(props) {
    const [reason, setReason] = useState("");
    const [submit, setSubmit] = useState(false);
    const [modalIsOpen, setIsModalOpen] = useState(false);
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        // Sort date by descending dateCreated
        var sortedStatuses = props.currentCase.case.statuses.sort((a, b) => {
            return new Date(b.dateCreated) - new Date(a.dateCreated);
        });

        setIsOpen(sortedStatuses[0].isOpen);
    }, [props.currentCase])

    // Open modal
    const toggleModal = () => setIsModalOpen(!modalIsOpen);
    
    // Update status and clear text area
    const updateStatus = async () => {
        let status = {
            isOpen: !isOpen,
            reason: reason
        }

        setIsOpen(!isOpen);
        await props.createStatus(status);
        await props.removeCaseFromSidebar(props.currentCase.case.caseId);
        await props.setCurrentCase(null);
    }

    // Delete case and update ui
    const deleteCase = async () => {
        await props.deleteCase(props.currentCase.case.caseId);
        await props.removeCaseFromSidebar(props.currentCase.case.caseId);
        await props.setCurrentCase(null);
    }

    // Handles user typing in text area
    const handleChange = (event) => {
        setReason(event.target.value);

        if (event.target.value !== "") {
            setSubmit(true);
        } else {
            setSubmit(false);
        }
    }

    return (
        <>
            {props.currentCase.case !== null &&
                <div className="case-subpage-container" style={{alignContent: "space-around"}}>
                    <div className="case-current-status-container">
                        {props.currentCase.case.statuses.length > 0 && props.currentCase.case.statuses.map((x, i) => (
                            <CaseStatusCard status={x}/>
                        ))}
                    </div>

                    {isOpen &&
                        <AccessControl
                            accessTypes={[
                                AccessType.IsLegalCounsel,
                                AccessType.IsChairPerson
                            ]}
                            renderNoAccess={() => ""}>
                            <div className="case-status-admin-container">
                                <TextArea maxLength={600} value={reason} onChange={handleChange}/>

                                <div style={{marginLeft: "auto", paddingTop: "20px"}}>
                                    <button className="btn btn-outline-primary"
                                            type="button"
                                            onClick={() => updateStatus()} disabled={!submit}>
                                        {"Close Case"}
                                    </button>
                                </div>
                            </div>
                        </AccessControl>
                    }
                    {!isOpen &&
                        <AccessControl
                            accessTypes={[
                                AccessType.IsLegalCounsel,
                                AccessType.IsChairPerson
                            ]}
                            renderNoAccess={() => ""}>
                            <div className="case-status-admin-container">
                                <div style={{marginLeft: "auto"}}>
                                    <button className="btn btn-outline-delete"
                                            type="button"
                                            onClick={toggleModal}>
                                        {"Delete Case"}
                                    </button>
                                </div>
                            </div>
                        </AccessControl>
                    }

                    <Modal className="case-status-modal-container"
                           isOpen={modalIsOpen}>
                        <div className="d-flex flex-column" style={{ margin: "20px" }}>
                            <div className="modal-question">
                                Are you sure you want to delete this case?
                            </div>
                            <div className="modal-buttons">
                                <button className="btn btn-outline-danger"
                                        type="button"
                                        onClick={toggleModal}>
                                    {"Cancel"}
                                </button>
                                <button className="btn btn-outline-primary"
                                        type="button"
                                        onClick={deleteCase}>
                                    {"Confirm"}
                                </button>
                            </div>
                        </div>
                    </Modal>
                </div>
            }
        </>
    )
}

export default CaseStatus;