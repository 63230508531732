import React, {Component} from "react";
import {UserAdminContext} from "../Contexts/UserAdminContext";
import axios from "axios";
import {toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export class UserAdminProvider extends Component {

    constructor(props) {
        toast.configure();
        super(props);
        this.state = {
            users: []
        };
    }

    // init
    componentDidMount() {
        this.getUsers();
    }

    // Call API to get all users with role
    getUsers = async () => {
        await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/api/Users/get-users-with-role`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${sessionStorage.getItem('bearerToken')}`
                }
            })
            .then(response => {
                if (response.status === 200) {
                    this.setState({users: response.data.users})
                } else {
                    toast.error("An unexpected error has occured. Please try again later. If the problem persists contact support for further assistance.", {
                        position: toast.POSITION.BOTTOM_CENTER
                    })
                }
            }).catch(() => {
                toast.error("An unexpected error has occured. Please try again later. If the problem persists contact support for further assistance.", {
                    position: toast.POSITION.BOTTOM_CENTER
                })
            })
    }
    
    // API call to delete User
    deleteUser = async (userId) => {
        await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/api/Users/remove-user?Id=${userId}`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${sessionStorage.getItem('bearerToken')}`
                }
            })
            .then(response => {
                if (response.status === 200) {
                    const newList = this.state.users.filter((item) => item.id !== userId);
                    this.setState({users: newList})
                    toast.success(`Successfully removed user`, {
                        position: toast.POSITION.BOTTOM_CENTER
                    });
                } else if (response.status === 500)
                    toast.error(response.data.error, {
                        position: toast.POSITION.BOTTOM_CENTER
                    });
                else
                    toast.error("An unexpected error has occured. Please try again later. If the problem persists contact support for further assistance.", {
                        position: toast.POSITION.BOTTOM_CENTER
                    })
            }).catch(() => {
                // ignore for now
                toast.error("An unexpected error has occured. Please try again later. If the problem persists contact support for further assistance.", {
                    position: toast.POSITION.BOTTOM_CENTER
                })
            })
    }

    // API Call to send password reset email 
    sendResetPasswordInvite = async (email) => {
        let encodedEmail = encodeURIComponent(email);
        await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/api/Users/send-reset-password-invite?email=${encodedEmail}`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${sessionStorage.getItem('bearerToken')}`
                }
            })
            .then(response => {
                if (response.status === 200) {
                    toast.success(`Successfully sent password reset request to ${email}.`, {
                        position: toast.POSITION.BOTTOM_CENTER
                    });
                } else if (response.status === 404)
                    toast.error("Cannot send email as it doesn't exist.", {
                        position: toast.POSITION.BOTTOM_CENTER
                    });
                else {
                    toast.error("An unexpected error has occured. Please try again later. If the problem persists contact support for further assistance.", {
                        position: toast.POSITION.BOTTOM_CENTER
                    })
                }
            })
    }

    // API Call to send password reset email 
    sendWelcomeInviteToUsers = async (event) => {
        event.preventDefault();
        let emailAddresses = document.getElementById('EmailTextArea').value;
        let role = document.getElementById('RoleDropDown').value;
        let emails = emailAddresses.split(',');
        for (let email of emails) {
            if (!this.isValidEmail(email))
                toast.error(`${email} is not a valid email. Please try again`, {
                    position: toast.POSITION.BOTTOM_CENTER
                }); else
                await this.sendWelcomeInvite(email, role)
        }
    }

    sendWelcomeInvite = async (email, role) => {
        let encodedEmail = encodeURIComponent(email);
        await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/api/Users/send-email-invite?email=${encodedEmail}&role=${role}`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${sessionStorage.getItem('bearerToken')}`
                }
            })
            .then(response => {
                if (response.status === 200) {
                    if (response.data === "User with an email already exists.")
                        toast.error(`User with the Email Address ${email} already exists`, {
                            position: toast.POSITION.BOTTOM_CENTER
                        });
                    else
                        toast.success(`Successfully sent invite reset request to ${email}.`, {
                            position: toast.POSITION.BOTTOM_CENTER
                        });
                } else if (response.status === 404) {
                    toast.error(`Please provide an email`, {
                        position: toast.POSITION.BOTTOM_CENTER
                    });
                } else
                    toast.error("An unexpected error has occured. Please try again later. If the problem persists contact support for further assistance.", {
                        position: toast.POSITION.BOTTOM_CENTER
                    })
            }).catch(() => {
                toast.error("An unexpected error has occured. Please try again later. If the problem persists contact support for further assistance.", {
                    position: toast.POSITION.BOTTOM_CENTER
                })
            })
    }

    isValidEmail = email => {
        const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return regex.test(email);
    };

    setUsers = value =>{
        this.setState({users: value })
    }

    render() {
        return (
            <UserAdminContext.Provider
                value={{
                    properties: this.state,
                    deleteUser: this.deleteUser,
                    sendResetPasswordInvite: this.sendResetPasswordInvite,
                    sendWelcomeInviteToUsers: this.sendWelcomeInviteToUsers,
                    updateEmail: this.updateEmail,
                    setUsers: this.setUsers
                }}
            >
                {this.props.children}
            </UserAdminContext.Provider>
        )
    }
}