import axios from "axios";

export interface UserPermissionResponse {
    hasAccess: boolean;
    status: NetworkRequestStatus;
    error: string;
}

export enum NetworkRequestStatus {
    Successful,
    Failed
}


export enum AccessType {
    IsItAdmin,
    IsExecutiveAssistant,
    IsLegalCounsel,
    IsChairPerson,
    IsUser
}

export const isItAdmin = async (
    endpoint: string,
) =>
    await axios
        .get<UserPermissionResponse>(endpoint + "Auth/is-it-admin", {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('bearerToken')}`
            }
        })
        .then(function(response) {
            return response.status === 401 || response.status === 403
                ? false
                : response.status !== 200
                    ? false
                    : response.data && response.data.status !== NetworkRequestStatus.Successful
                        ? false
                        : response.status === 200 && response.data && response.data.status === NetworkRequestStatus.Successful
                            ? response.data.hasAccess
                            : false;
        })
        .catch(e => false);


export const isExecutiveAssistant = async (
    endpoint: string,
) =>
    await axios
        .get<UserPermissionResponse>(endpoint + "Auth/is-executive-assistant", {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('bearerToken')}`
            }
        })
        .then(function(response) {
            return response.status === 401 || response.status === 403
                ? false
                : response.status !== 200
                    ? false
                    : response.data && response.data.status !== NetworkRequestStatus.Successful
                        ? false
                        : response.status === 200 && response.data && response.data.status === NetworkRequestStatus.Successful
                            ? response.data.hasAccess
                            : false;
        })
        .catch(e => false);


export const isLegalCounsel = async (
    endpoint: string,
) =>
    await axios
        .get<UserPermissionResponse>(endpoint + "Auth/is-legal-counsel", {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('bearerToken')}`
            }
        })
        .then(response => response.status === 401 || response.status === 403
            ? false
            : response.status !== 200
                ? false
                : response.data && response.data.status !== NetworkRequestStatus.Successful
                    ? false
                    : response.status === 200 && response.data && response.data.status === NetworkRequestStatus.Successful
                        ? response.data.hasAccess
                        : false)
        .catch(e => false);


export const isChairPerson = async (
    endpoint: string,
) =>
    await axios
        .get<UserPermissionResponse>(endpoint + "Auth/is-chair-person", {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('bearerToken')}`
            }
        })
        .then(function(response) {
            return response.status === 401 || response.status === 403
                ? false
                : response.status !== 200
                    ? false
                    : response.data && response.data.status !== NetworkRequestStatus.Successful
                        ? false
                        : response.status === 200 && response.data && response.data.status === NetworkRequestStatus.Successful
                            ? response.data.hasAccess
                            : false;
        })
        .catch(e => false);

