import React, {useState, useContext} from "react";
import CategoryElement from "../../CategoryElement";
import "./category.css";
import {CaseContext} from "../../Contexts/CaseContext";
import Categories from "../../../JsonObjects/Categories.json";

function Category(props) {
    const {currentCase, setActivePage, updateTimeline} = useContext(CaseContext);

    // Updates timeline as well as set active page as next page
    const nextPage = () => {
        setActivePage("Additional Information");
        updateTimeline("Additional Information", true);
    }

    // Updates timeline as well as set active page as previous page
    const prevPage = () => {
        setActivePage("Report");
        updateTimeline("Category", false);
    }

    return (
        <>
            {currentCase.activePage === "Category" &&
            <div>
                <div>
                    <h2>Please select a category</h2>
                    <p>Select the one category that you feel best matches the main issue in your report.</p>
                </div>
                {Categories.categories.map((category) => (
                    <div className="mb-2" key={`${category.category}`}>
                        <h3>{category.title}</h3>
                        <div>
                            {category.subCategories.map((subCategory) => (
                                <CategoryElement message={subCategory.message}
                                                 category={category.category}
                                                 categoryTitle={category.title}
                                                 index={subCategory.index}
                                                 key={`${category.category}-${subCategory.index}`}
                                                 tooltipHeader={subCategory.tooltipHeader}
                                                 tooltipBody={subCategory.tooltipBody}
                                />
                            ))}
                        </div>
                    </div>
                ))}
                <div className="d-flex flex-sm-row mt-5">
                    <button type="button" className="btn btn-outline-danger" onClick={() => prevPage()}>Back</button>
                    <button type="button" className="btn btn-outline-primary btn-ml-25" disabled={currentCase.selectedCategory === null} onClick={() => nextPage()}>Continue</button>
                </div>
            </div>
            }
        </>
    )
}

export default Category;