import React, {useContext, useEffect, useState} from "react";
import './LoginPage.css';
import axios from "axios";
import {Redirect} from "react-router-dom";
import {AuthContext} from "../../Contexts/AuthContext";
import {NetworkRequestStatus} from "../../Auth/AccessControlService";
import {toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


const LoginPage = () => {
    const {setCaseId, setBearerToken, setUserId, setUserName, setEmail} = useContext(AuthContext);
    const [user, setUser] = useState(null);
    const [password, setPassword] = useState(null);
    const [redirect, setRedirect] = useState(false);
    const [loading, setLoading] = useState(false);
    toast.configure();
    
    useEffect(() => {
        let bearerToken = sessionStorage.getItem('bearerToken')
        if(bearerToken)
            setRedirect(true)
    },[])

    const submit = async (e) => {
        setLoading(true)
        e.preventDefault();
        await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/api/Auth/get-bearer-token`,
            JSON.stringify({
                "user": user,
                "password": password
            }),
            {
                headers: {
                    "Content-Type": "application/json"
                }
            })
            .then(response => {
                if (response.status === 200)
                    if (response.data.status === NetworkRequestStatus.Failed)
                        toast.error("Invalid Case Number or Password. Please re enter your credentials", {
                            position: toast.POSITION.BOTTOM_CENTER
                        })
                    else {
                        toast.success("Login Successful", {
                            position: toast.POSITION.BOTTOM_CENTER
                        })
                        setBearerToken(response.data.token)
                        setCaseId(response.data.caseId);
                        setUserId(response.data.caseId);
                        setUserName(response.data.userName);
                        setEmail(response.data.email);
                        setRedirect(true)
                    }
                else if (response.status === 500)
                    toast.error("An unexpected error has occured. Please try again later. If the problem persists contact support for further assistance.", {
                        position: toast.POSITION.BOTTOM_CENTER
                    })
            }).catch(() => {
                toast.error("An unexpected error has occured. Please try again later. If the problem persists contact support for further assistance.", {
                    position: toast.POSITION.BOTTOM_CENTER
                })
            })
        setLoading(false)
    }

    if (redirect) {
        return <Redirect to="/home"/>
    }
    return (
        <main className="form-signin">
            <form onSubmit={submit}>
                <h1 className="h3 mb-3 fw-normal">Please Sign In</h1>
                <input id="inputEmail" className="form-control" placeholder="Enter Case Number/User Name"
                       onChange={e => setUser(e.target.value)}
                       required autoFocus/>
                <input type="password" id="inputPassword" className="form-control" placeholder="Password"
                       onChange={e => setPassword(e.target.value)}
                       required/>
                <button className="w-100 btn btn-lg btn-outline-primary" type="submit" disabled={loading}>
                    {loading ? "Loading..." : "Sign In"}
                </button>
            </form>
        </main>
    )
};

export default LoginPage;