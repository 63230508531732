import React, {useContext, useEffect, useState} from "react";
import {BrowserRouter, Route, Switch} from "react-router-dom";
import NewCase from "./Components/Pages/NewCase/NewCase";
import LoginPage from "./Components/Pages/LoginPage/LoginPage";
import MainPage from "./Components/Pages/MainPage/MainPage";
import RegisterPage from "./Components/Pages/RegisterPage/RegisterPage";
import Header from "./Components/Header/Header";
import Admin from "./Components/Pages/Admin/Admin";
import {AccessControlContext} from "./Components/Contexts/AccessControlContext";
import {AdminProvider} from "./Components/Providers/AdminProvider";
import {AuthProvider} from "./Components/Providers/AuthProvider";
import {UserProvider} from "./Components/Providers/UserProvider";
import {CaseProvider} from "./Components/Providers/CaseProvider";
import './App.css';
import "./FontAwesomeIcons";
import User from "./Components/Pages/User/User";
import {UserAdminProvider} from "./Components/Providers/UserAdminProvider";
import UserAdmin from "./Components/Pages/UserAdminPage/UserAdmin";
import {ResetPassword} from "./Components/Pages/ResetPasswordPage/ResetPassword";
import {CreateUser} from "./Components/Pages/CreateUserPage/CreateUser";
import ErrorBoundary from "./Components/ErrorBoundary";
import NotFound from "./Components/NotFound";
import PrivateRoute from "./Components/PrivateRoute";
import Home from "./Components/Pages/Home/Home";
import {HeaderContext} from "./Components/Contexts/HeaderContext";
import CacheBuster from "react-cache-buster";
import {version} from '../package.json';
import Loader from "./Components/Pages/Loader/Loader"

function App() {
    const isProduction = process.env.NODE_ENV === 'production';
    const [access, setAccess] = useState(false)
    const [showExistingCaseButton, setShowExistingCaseButton] = useState(false)

    return (
        <CacheBuster
            currentVersion={version}
            isEnabled={isProduction}
            isVerboseMode={false}
            loadingComponent={<Loader/>}
        >
            <AccessControlContext.Provider value={{access, setAccess}}>
                <AuthProvider>
                    <BrowserRouter>
                        <ErrorBoundary>
                            <HeaderContext.Provider
                                value={{showExistingCaseButton, setShowExistingCaseButton}}>
                                <Header/>
                                <Switch>
                                    <Route exact path="/">
                                        <MainPage/>
                                    </Route>
                                    <PrivateRoute exact path="/home" component={Home}/>
                                    <PrivateRoute exact path="/new-case" component={NewCase}
                                                  provider={CaseProvider}/>
                                    <PrivateRoute exact path="/users-admin" component={UserAdmin}
                                                  provider={UserAdminProvider}/>
                                    <Route exact path="/login">
                                        <LoginPage/>
                                    </Route>
                                    <Route exact path="/register-new-page">
                                        <RegisterPage/>
                                    </Route>
                                    <PrivateRoute exact path="/admin" component={Admin}
                                                  provider={AdminProvider}/>
                                    <PrivateRoute exact path="/existing-case" component={User}
                                                  provider={UserProvider}/>
                                    <Route exact path="/reset-password">
                                        <ResetPassword/>
                                    </Route>
                                    <Route exact path="/create-user">
                                        <CreateUser/>
                                    </Route>
                                    <Route path="*" exact component={NotFound} status={404}/>
                                </Switch>
                            </HeaderContext.Provider>
                        </ErrorBoundary>
                    </BrowserRouter>
                </AuthProvider>
            </AccessControlContext.Provider>
        </CacheBuster>
    );
}

export default App;
