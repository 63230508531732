import {moment} from "moment";

function formatDateTime( str ) {
    if (str === undefined || str === null)
        return null;
    
    // Checks if datetime string
    if (!isNaN(Date.parse(str))) {
        return moment(str).format('LL');
    } else {
        throw 'Not a valid DateTime string!';
    }
} 

String.prototype.formatDateTime = formatDateTime();
