import React, {useEffect, useState} from "react";
import {Link, useLocation} from "react-router-dom";
import axios from "axios";
import {toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


export const ResetPassword = () => {
    const [email, setEmail] = useState(null);
    const [resetPasswordToken, setResetPasswordToken] = useState(null);
    const [newPassword, setNewPassword] = useState(null)
    const [confirmPassword, setConfirmPassword] = useState(null)
    const [redirectToLogin, setRedirectToLogin] = useState(false)
    const [loading, setLoading] = useState(false);
    const search = useLocation().search;
    toast.configure();

    useEffect(() => {
        setEmail(new URLSearchParams(search).get('email'))
        setResetPasswordToken(new URLSearchParams(search).get('token'))
    }, [])

    const submit = async (e) => {
        setLoading(true)
        e.preventDefault();
        if (newPassword === confirmPassword) {
            await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/api/Users/reset-password`,
                JSON.stringify({
                    "token": resetPasswordToken,
                    "email": email,
                    "newPassword": newPassword,
                    "confirmPassword": confirmPassword
                }),
                {
                    headers: {
                        "Content-Type": "application/json"
                    }
                })
                .then(response => {
                    if (response.status === 200) {
                        if (response.data.status === 'Success') {
                            toast.success(`Password Reset Successful.`, {
                                position: toast.POSITION.BOTTOM_CENTER
                            });
                            setRedirectToLogin(true)
                            setLoading(false)
                        } else if (response.data.status === 'Failed')
                            toast.error(response.data.errors[0], {
                                position: toast.POSITION.BOTTOM_CENTER
                            });
                        setLoading(false)

                    }
                })
        } else {
            toast.error("The passwords don't match. Please Try again", {
                position: toast.POSITION.BOTTOM_CENTER
            });
            setLoading(false)
        }
    }

    return (<>
        {email && resetPasswordToken && (<main className="form-signin">
            <form onSubmit={submit}>
                <h1 className="h3 mb-3 fw-normal">Reset Password</h1>
                <input type="password" id="inputEmail" className="form-control" placeholder="Enter New Password"
                       onChange={e => setNewPassword(e.target.value)}
                       required autoFocus/>
                <input type="password" id="inputPassword" className="form-control" placeholder="Confirm New Password"
                       onChange={e => setConfirmPassword(e.target.value)}
                       required/>
                <div><strong>Password must contain</strong></div>
                <ul>
                    <li>At least 12 characters—the more characters, the better</li>
                    <li>A mixture of both uppercase and lowercase letters</li>
                    <li>Inclusion of at least one special character, e.g., ! @ # ? ]</li>
                    <li>A mixture of letters and numbers</li>
                </ul>
                <button className="w-100 btn btn-lg btn-primary" type="submit" disabled={loading}>  
                    {loading ? "Loading..." : "Reset Password"}
                </button>
                {redirectToLogin && (<>
                    <br/>
                    <br/>
                    <Link className="w-100 btn btn-lg btn-success" to='/login'>Proceed to Login Page</Link>
                </>)}
            </form>
        </main>)}

    </>)
}