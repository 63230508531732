import React, {useContext, useEffect, useState} from 'react';
import {AdminContext} from "./Contexts/AdminContext";
import "../Helpers/StringExtension";
import moment from "moment";

function Case(props) {
    const {properties, setCurrentCase} = useContext(AdminContext);
    
    return (
        <>
            <div
                className={`list-group-item list-group-item-action case-item ${properties.currentCase.case !== null && properties.currentCase.case.caseId === props.case.caseId ? 'case-item-active' : ''}`}
                onClick={() => setCurrentCase(props.case)}>
                <div>
                    <div className="d-flex w-100 justify-content-between">
                        <h5 className="case-id mb-1">{props.case.caseNumber}</h5>
                        <small>{moment(props.case.dateCreated).format('MMMM Do YYYY, h:mm:ss a')}</small>
                    </div>
                    <p className="case-report ofe mb-1">{props.case.report}</p>
                </div>
            </div>
        </>
    )
}

export default Case;