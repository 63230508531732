import React, {useContext, useState} from "react";
import './RegisterPage.css';
import axios from "axios";
import {AuthContext} from "../../Contexts/AuthContext";
import {Link} from "react-router-dom";
import {toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {HeaderContext} from "../../Contexts/HeaderContext";


const RegisterPage = () => {
    const [userEmail, setUserEmail] = useState("");
    const [user, setUser] = useState(null);
    const [password, setPassword] = useState(null);
    const {setCaseId, setBearerToken, setUserId, setUserName, setEmail} = useContext(AuthContext);
    const [loading, setLoading] = useState(false);
    const {setShowExistingCaseButton} = useContext(HeaderContext)

    toast.configure()
    const submit = async (e) => {
        setLoading(true)

        e.preventDefault();
        let encodedEmail = encodeURIComponent(userEmail);
        await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/api/Users/create-new-account?email=${encodedEmail}`,
            {},
            {
                headers: {
                    "Content-Type": "application/json"
                }
            })
            .then(response => {
                if (response.status === 200) {
                    toast.success('Case Created Successfully',
                        {
                            position: toast.POSITION.BOTTOM_CENTER
                        })
                    setUser(response.data.user);
                    setPassword(response.data.password);
                    setBearerToken(response.data.token);
                    setCaseId(response.data.caseId);
                    setUserId(response.data.caseId);
                    setUserName(response.data.userName);
                    setEmail(userEmail);
                    setLoading(false)
                    setShowExistingCaseButton(false)

                } else {
                    toast.error("An unexpected error has occured. Please try again later. If the problem persists contact support for further assistance.", {
                        position: toast.POSITION.BOTTOM_CENTER
                    })
                    setLoading(false)
                }
            }).catch((e) => {
                toast.error("An unexpected error has occured. Please try again later. If the problem persists contact support for further assistance.", {
                    position: toast.POSITION.BOTTOM_CENTER
                })
                setLoading(false)
            })
    }

    return (
        <main className="form-signin">
            <form onSubmit={submit}>
                <h1 className="h3 mb-3 fw-normal">Enter your email</h1>
                <p style={{"padding-top": "10px"}}>Email is Optional. It will be used to send your generated case number
                    and password.</p>
                <input type="email" id="inputEmail" className="form-control" placeholder="Email"
                       onChange={e => setUserEmail(e.target.value)}
                       disabled={user && password}
                />
                <br/>
                <button className="w-100 btn btn-lg btn-outline-primary" type="submit"
                        disabled={loading || user && password}>
                    {loading ? "Loading..." : "Submit"}
                </button>
                {user &&
                (<>
                    <p style={{"padding-top": "10px"}}>
                        We recommend storing the generated case number and password below
                        somewhere safe for later access.
                    </p>
                    <p style={{"padding-top": "10px"}}>
                        Signing in to the site will allow you to check your previous response to
                        questions, see previous attachments, view your case status, as well as let you chat anonymously
                        on a case forum with either Robert Pacileo or the Chair Person.
                    </p>
                    <p style={{"padding-top": "10px"}}>
                        If you provided an email it has been emailed
                        to you at the provided email.
                    </p>
                    <div className="alert alert-success">
                        <strong>Case Number:</strong> {user}
                        <br/>
                        <strong>Password:</strong> {password}
                    </div>
                    <Link className="w-100 btn btn-lg btn-outline-success" to='/home'>Proceed to Home Page</Link>
                </>)
                }
            </form>
        </main>
    )
};

export default RegisterPage;